import React, { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useMount } from "ahooks";
import * as Sentry from "@sentry/react";

// eslint-disable-next-line no-undef
const CURRENT_VERSION = BUILD_RELEASE_VERSION; // webpack defined
// eslint-disable-next-line no-undef
const CURRENT_RELEASE_DATE = BUILD_RELEASE_DATE; // webpack defined

const CHECK_INTERVAL_IN_MS = 5 * 60 * 1000; // min * sec * ms

/**
 * Reloads tab if bundle version changed
 * @returns {null}
 * @constructor
 */
const ReleaseChecker = () => {
  const history = useHistory();
  const timeoutRef = useRef(null);
  const isReloadPlannedRef = useRef(false);
  const prevPathNameRef = useRef(history.location.pathname);

  const checkRelease = useCallback(async () => {
    clearTimeout(timeoutRef.current);
    if (!isReloadPlannedRef.current) {
      try {
        const releaseInfoRequest = await fetch(`/release-info.json`, { cache: "no-cache" });
        if (releaseInfoRequest.ok) {
          const releaseInfoText = await releaseInfoRequest.text();
          const releaseInfo = JSON.parse(releaseInfoText);
          if (
            typeof releaseInfo === `object` &&
            releaseInfo !== null &&
            ((typeof releaseInfo.version === `string` && releaseInfo.version !== CURRENT_VERSION) ||
              (typeof releaseInfo.date === `string` && releaseInfo.date !== CURRENT_RELEASE_DATE))
          ) {
            // reload on route change (usually user interaction)
            history.listen((location) => {
              // check pathname only. hash and search should not trigger reload
              if (prevPathNameRef.current !== location.pathname) {
                window.location.reload();
              }
            });
            isReloadPlannedRef.current = true;
          }
        }
      } catch (e) {
        // do nothing if request failed, do not propagate error to boundary and exclude app crash
        Sentry.captureException(e);
      }
    }
    timeoutRef.current = setTimeout(checkRelease, CHECK_INTERVAL_IN_MS);
  }, []);

  useMount(() => {
    history.listen((location) => setTimeout(() => (prevPathNameRef.current = location.pathname), 0)); // save new pathname with next async tick
    checkRelease();
  });

  return null;
};

export default ReleaseChecker;
