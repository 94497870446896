// modules
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

// components
import { makeStyles, Collapse, Divider, List, ListItem, ListItemIcon, ListItemText, SvgIcon } from "@material-ui/core";

import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import GroupWork from "@material-ui/icons/GroupWork";
import InboxIcon from "@material-ui/icons/Inbox";
import LabelIcon from "@material-ui/icons/Label";
import RateReviewIcon from "@material-ui/icons/RateReview";
import RefTableIcon from "@material-ui/icons/BlurOn";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import SecurityIcon from "@material-ui/icons/Security";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import TableChartIcon from "@material-ui/icons/TableChart";

import { emphasize } from "@material-ui/core/styles/colorManipulator";

// hooks
import { useAuth } from "../hoc/withAuth";
import useRoutesNames from "../routing/useRoutesNames";
import useI18nContext from "../utils/hooks/useI18nContext";

// utils
import cn from "classnames";
import configService from "../configService";

// styles
const useStyles = makeStyles((theme) => ({
  nested: {
    "&$expanded": {
      paddingLeft: theme.spacing(4),
    },
  },
  expanded: {
    "&$active": {
      "&::before": {
        left: "initial",
        right: 0,
      },
    },
  },
  active: {
    backgroundColor: theme.palette.grey["200"],
    position: "relative",
    "&::before": {
      content: `""`,
      display: `block`,
      position: "absolute",
      zIndex: 1,
      top: 0,
      left: 69,
      height: `100%`,
      width: `3px`,
      background: "#00408F",
    },
  },
}));

// MenuItem
const MenuItem = (props) => {
  const { children, isShowed, to, title, isSmallFont, ...rest } = props;

  const css = useStyles();

  if (!isShowed) {
    return null;
  }
  return (
    <ListItem component={NavLink} to={to} button title={title} activeClassName={css.active} {...rest}>
      <ListItemIcon>{children}</ListItemIcon>

      <ListItemText
        primary={title}
        primaryTypographyProps={{ variant: isSmallFont ? "caption" : "body2", noWrap: true }}
      />
    </ListItem>
  );
};

MenuItem.propTypes = {
  isShowed: PropTypes.bool,
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  isSmallFont: PropTypes.bool,
};

MenuItem.defaultProps = {
  isShowed: true,
};

// icons
const SummarizeIcon = () => (
  <SvgIcon viewBox="0 0 24 24" nativecolor="#6c6c6c">
    <path d="M15 3H5c-1.1 0-1.99.9-1.99 2L3 19c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V9l-6-6zM8 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-4c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 1V4.5l5.5 5.5H14z" />{" "}
  </SvgIcon>
);

const SspIcon = () => (
  <SvgIcon viewBox="0 0 58.201 58.201" nativecolor="#6c6c6c">
    {/* eslint-disable no-mixed-spaces-and-tabs */}
    <path d="M31.707,33.07c-0.87,0.027-1.74,0.042-2.606,0.042c-0.869,0-1.742-0.014-2.614-0.042c-7.341-0.201-13.191-1.238-17.403-2.717C7.104,29.685,5.409,28.899,4.1,28v7.111v0.5v0.5V37.4c2.846,2.971,12.394,5.711,25,5.711s22.154-2.74,25-5.711v-1.289v-0.5v-0.5V28c-1.318,0.905-3.028,1.697-5.025,2.367C44.865,31.839,39.027,32.87,31.707,33.07z" />
    <path d="M4.1,14.889V22v0.5V23v1.289c2.638,2.754,11.033,5.31,22.286,5.668c0.115,0.004,0.233,0.005,0.349,0.008c0.326,0.009,0.651,0.018,0.982,0.023C28.174,29.996,28.635,30,29.1,30s0.926-0.004,1.383-0.011c0.33-0.005,0.656-0.014,0.982-0.023c0.116-0.003,0.234-0.005,0.349-0.008c11.253-0.359,19.648-2.915,22.286-5.668V23v-0.5V22v-7.111C49.233,18.232,38.944,20,29.1,20S8.968,18.232,4.1,14.889z" />
    <path d="M53.965,8.542C52.843,4.241,44.215,0,29.1,0C14.023,0,5.404,4.22,4.247,8.51C4.162,8.657,4.1,8.818,4.1,9v0.5v1.806C6.937,14.267,16.417,17,29.1,17s22.164-2.733,25-5.694V9.5V9C54.1,8.832,54.044,8.681,53.965,8.542z" />
    <path d="M4.1,41v8.201c0,0.162,0.043,0.315,0.117,0.451c1.181,4.895,11.747,8.549,24.883,8.549c13.106,0,23.655-3.639,24.875-8.516c0.08-0.144,0.125-0.309,0.125-0.484v-8.199c-4.135,2.911-12.655,5.199-25,5.199C16.754,46.201,8.234,43.911,4.1,41z" />
  </SvgIcon>
);

const HandshakeOutlinedIcon = () => (
  <SvgIcon viewBox="0 0 24 24" nativecolor="#6c6c6c">
    <path d="M12.22 19.85c-.18.18-.5.21-.71 0-.18-.18-.21-.5 0-.71l3.39-3.39-1.41-1.41-3.39 3.39c-.19.2-.51.19-.71 0-.21-.21-.18-.53 0-.71l3.39-3.39-1.41-1.41-3.39 3.39c-.18.18-.5.21-.71 0-.19-.19-.19-.51 0-.71l3.39-3.39-1.42-1.41-3.39 3.39c-.18.18-.5.21-.71 0-.19-.2-.19-.51 0-.71L9.52 8.4l1.87 1.86c.95.95 2.59.94 3.54 0 .98-.98.98-2.56 0-3.54l-1.86-1.86.28-.28c.78-.78 2.05-.78 2.83 0l4.24 4.24c.78.78.78 2.05 0 2.83l-8.2 8.2zm9.61-6.78c1.56-1.56 1.56-4.09 0-5.66l-4.24-4.24c-1.56-1.56-4.09-1.56-5.66 0l-.28.28-.28-.28c-1.56-1.56-4.09-1.56-5.66 0L2.17 6.71C.75 8.13.62 10.34 1.77 11.9l1.45-1.45c-.39-.75-.26-1.7.37-2.33l3.54-3.54c.78-.78 2.05-.78 2.83 0l3.56 3.56c.18.18.21.5 0 .71-.21.21-.53.18-.71 0L9.52 5.57l-5.8 5.79c-.98.97-.98 2.56 0 3.54.39.39.89.63 1.42.7.07.52.3 1.02.7 1.42.4.4.9.63 1.42.7.07.52.3 1.02.7 1.42.4.4.9.63 1.42.7.07.54.31 1.03.7 1.42.47.47 1.1.73 1.77.73.67 0 1.3-.26 1.77-.73l8.21-8.19z" />
  </SvgIcon>
);

const DatasetIcon = ({ color }) => (
  <SvgIcon viewBox="0 0 24 24" color={color}>
    <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8 14H7v-4h4v4zm0-6H7V7h4v4zm6 6h-4v-4h4v4zm0-6h-4V7h4v4z"></path>
  </SvgIcon>
);

// Menu
const Menu = (props) => {
  const { isOpen } = props;

  const { t } = useTranslation("common");
  const css = useStyles();

  const auth = useAuth();
  const location = useLocation();
  const { paths } = useRoutesNames();

  // state
  const [isAdminOpen, setIsAdminOpen] = useState(
    location.pathname.startsWith(`/admin`) || location.pathname.startsWith(paths.agenciesBase)
  );

  // constants
  const context = useI18nContext();

  const IS_RETAIL_MODE = configService.IS_RETAIL_MODE;

  const showLists = IS_RETAIL_MODE
    ? Boolean(auth.limitCampaignTypesData === null || auth.limitCampaignTypesData.banner_ad)
    : auth.hasAccessToAdvertisers;

  // methods
  const handleToggleAdminSection = () => {
    setIsAdminOpen(!isAdminOpen);
  };

  return (
    <List dense>
      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isActive={(match, location) => location.pathname.startsWith(paths.advertisersBase)}
        isShowed={auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        title={t("advertisers", { context })}
        to={`${paths.advertisersBase}/list`}
      >
        <InboxIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={auth.hasAccessToReport}
        title={t("reports")}
        to="/reporting"
      >
        <TableChartIcon className={css.icon} />
      </MenuItem>

      <Divider />

      <MenuItem className={cn({ [css.expanded]: isOpen })} isShowed={auth.hasAccessToLogs} title={t("logs")} to="/logs">
        <ChangeHistoryIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isActive={(match, location) => location.pathname.startsWith(`/sites`)}
        isShowed={auth.hasAccessToPixels}
        title={t("pixels")}
        to="/sites/list"
      >
        <ScatterPlotIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={showLists}
        title={t("globalLists")}
        to="/global_lists"
      >
        <FormatListBulletedIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={!auth.isAdmin && auth.hasAccessToManageSegments}
        title={t("segments")}
        to="/segments"
      >
        <CloudDoneIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={auth.hasAccessToModeration}
        title={t("moderation")}
        to="/moderation"
      >
        <RateReviewIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={auth.hasAccessToAudienceReports}
        title={t("audienceReports")}
        to="/audience_reports"
      >
        <SummarizeIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={!auth.isAdmin && auth.hasAccessToManageAgencies}
        title={t("agencies", { ns: "common", context })}
        to={paths.agenciesBase}
      >
        <AttachMoneyIcon />
      </MenuItem>

      <MenuItem
        className={cn({ [css.expanded]: isOpen })}
        isShowed={!auth.isAdmin && auth.hasAccessToUsers}
        title={t("users")}
        to="/admin/users"
      >
        <SupervisorAccountIcon />
      </MenuItem>

      <Divider />

      {auth.isAdmin && (
        <ListItem
          className={cn({ [css.active]: isAdminOpen, [css.expanded]: isOpen })}
          button
          title={t("admin")}
          onClick={handleToggleAdminSection}
        >
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>

          <ListItemText primary={t("admin")} />

          {isAdminOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )}

      {auth.isAdmin && (
        <Collapse in={isAdminOpen} timeout={`auto`}>
          <List component="div" disablePadding dense>
            <MenuItem
              className={cn(css.nested, { [css.expanded]: isOpen })}
              title={t("resellers", { context: !IS_RETAIL_MODE ? null : "retail" })}
              to={paths.resellerBase}
            >
              <HandshakeOutlinedIcon />
            </MenuItem>

            <MenuItem
              className={cn(css.nested, { [css.expanded]: isOpen })}
              title={t("spsegments")}
              to="/admin/spsegments/loader"
            >
              <CloudUploadIcon />
            </MenuItem>

            <MenuItem className={cn(css.nested, { [css.expanded]: isOpen })} title={t("segments")} to="/admin/segments">
              <CloudDoneIcon />
            </MenuItem>

            <MenuItem
              className={cn(css.nested, { [css.expanded]: isOpen })}
              title={t("refTables")}
              to="/admin/reftables"
            >
              <RefTableIcon />
            </MenuItem>

            <MenuItem className={cn(css.nested, { [css.expanded]: isOpen })} title={t("datasets")} to="/admin/datasets">
              <DatasetIcon />
            </MenuItem>

            <MenuItem
              className={cn(css.nested, { [css.expanded]: isOpen })}
              title={t("agencies", { ns: "common", context })}
              to={paths.agenciesBase}
            >
              <AttachMoneyIcon />
            </MenuItem>

            <MenuItem className={cn(css.nested, { [css.expanded]: isOpen })} title={t("users")} to="/admin/users">
              <SupervisorAccountIcon />
            </MenuItem>

            <MenuItem className={cn(css.nested, { [css.expanded]: isOpen })} title={t("roles")} to="/admin/roles">
              <GroupWork />
            </MenuItem>

            <MenuItem
              className={cn(css.nested, { [css.expanded]: isOpen })}
              isSmallFont
              title={t("ssp")}
              to="/admin/supply-side-platforms"
            >
              <SspIcon />
            </MenuItem>

            <MenuItem
              className={cn(css.nested, { [css.expanded]: isOpen })}
              isSmallFont
              title={t("templates")}
              to="/admin/dco-feed-templates"
            >
              <AspectRatioIcon />
            </MenuItem>

            <MenuItem className={cn(css.nested, { [css.expanded]: isOpen })} title={t("whitelabel")} to="/admin/wl">
              <LabelIcon />
            </MenuItem>
          </List>
        </Collapse>
      )}
    </List>
  );
};

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default Menu;
