// modules
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// components
import { LinearProgress, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import NotificationCenter from "./components/NotificationCenter";
import PageTitle from "./components/PageTitle/PageTitle";
import ReleaseChecker from "./components/ReleaseChecker/ReleaseChecker";
import Routes from "./routing/Routes";

// hooks
import { useAuth } from "./hoc/withAuth";

// utils
import moment from "moment";
import ru from "moment/locale/ru";
import en from "moment/locale/en-gb";

const setMomentLocale = (language) => {
  moment.locale(language === "ru" ? "ru" : "en-gb", language === "ru" ? ru : en);
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".primaryLink": {
      color: theme.palette.primary.main,
      textDecoration: "none",

      "&:active": {
        color: theme.palette.primary.main,
      },

      "&:visited": {
        color: theme.palette.primary.main,
      },
    },
  },

  colorPrimary: {
    backgroundColor: "#c2c2c2",
  },
  barColorPrimary: {
    backgroundColor: "#616161",
  },
}));

const App = () => {
  const css = useStyles();
  const { i18n, t } = useTranslation("theme");

  // state
  const { user, loading } = useAuth();
  const [isAuthLoading, setIsAuthLoading] = useState(loading);

  // constants
  const language = user?.lang?.toLowerCase?.() ?? window.navigator.language?.slice?.(0, 2); // fallback to main system language from navigator.language: 'ru-Ru' > 'ru', 'en-US' > 'en' and etc
  const currentLanguage = i18n.language;
  setMomentLocale(language);

  const faviconUrl = t("faviconUrl");

  // effects
  useEffect(() => {
    if (language && language !== currentLanguage) {
      i18n?.changeLanguage?.(language);
    }
  }, [user]);

  useEffect(() => {
    if (loading) {
      setIsAuthLoading(loading);
    } else {
      setTimeout(() => setIsAuthLoading(loading), 100); // back to normal with delay to update theme
    }
  }, [loading]);

  if (isAuthLoading) {
    return (
      <LinearProgress
        color="primary"
        variant="indeterminate"
        classes={{ colorPrimary: css.colorPrimary, barColorPrimary: css.barColorPrimary }}
      />
    );
  }

  return (
    <NotificationCenter>
      <ReleaseChecker />
      <PageTitle />
      <Helmet>
        {faviconUrl && <link rel="shortcut icon" href={faviconUrl} />}
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <Routes />
    </NotificationCenter>
  );
};

export default App;
