// modules
import React, { useState } from "react";
import PropTypes from "prop-types";

// components
import Alert from "@material-ui/lab/Alert";
import { makeStyles, Grid, Slide } from "@material-ui/core";

// hooks
import { useMount } from "ahooks";

// utils
import cn from "classnames";

// styles
const useStyles = makeStyles(() => ({
  notification: {
    margin: "8px 0",
    position: "relative",
  },
  connector: {
    position: "absolute",
    right: 0,
    top: -55,
    borderRight: "3px solid rgb(88, 160, 238)",
    height: "calc(100% + 56px)",
    width: 0,
    margin: "-1px 0",
  },
  child: {
    marginLeft: 28,
  },
  error: {
    borderColor: "rgb(228, 98, 82)",
  },
  warning: {
    borderColor: "rgb(242, 165, 74)",
  },
  info: {
    borderColor: "rgb(88, 160, 238)",
  },
  success: {
    borderColor: "rgb(117, 180, 106)",
  },
}));

// static
const TIMEOUT_TO_AUTO_CLOSE_NOTIFICATIONS_IN_MS = 4000;
const TIMEOUT_TO_AUTO_CLOSE_ERRORS_IN_MS = 20000;

const Notification = (props) => {
  const { className, color, nestedNotifications, xs, severity, variant, onRemove } = props;

  const css = useStyles();

  // state
  const [isShowed, setIsShowed] = useState(true);

  // methods
  const handleHide = () => {
    setIsShowed(false);
  };

  // effects
  useMount(() => {
    let timeoutId = null; // auto hide

    const timeoutDelay =
      severity === "error" ? TIMEOUT_TO_AUTO_CLOSE_ERRORS_IN_MS : TIMEOUT_TO_AUTO_CLOSE_NOTIFICATIONS_IN_MS;

    timeoutId = setTimeout(handleHide, timeoutDelay);

    return () => clearTimeout(timeoutId);
  });

  return (
    <Slide direction="left" in={isShowed} mountOnEnter timeout={400} unmountOnExit onExited={onRemove}>
      <div>
        <Grid className={cn(css.notification, className)} item xs={xs}>
          <Alert severity={severity} onClose={handleHide}>
            {props.children}
          </Alert>
        </Grid>

        {nestedNotifications?.map?.(({ title }, index) => {
          return (
            <Grid key={index} className={cn(css.notification, className, css.child)} item xs={11}>
              <div className={cn(css.connector, css[color])} />

              <Alert icon={false} severity={severity} variant={variant}>
                {title}
              </Alert>
            </Grid>
          );
        })}
      </div>
    </Slide>
  );
};

Notification.propTypes = {
  xs: PropTypes.number,
};

Notification.defaultProps = {
  xs: 12,
};

export default Notification;
