import i18n from "i18next";

export const parseGQLvalidation = (error) => {
  const errors = error?.graphQLErrors || [];
  const fields = {};
  errors.forEach(({ extensions, validation }) => {
    const errorFields = { ...(extensions?.validation || {}), ...validation };
    const validationFields = validation || extensions?.validation;
    if (validationFields) {
      Object.keys(errorFields).forEach((field) => {
        fields[field] = i18n.t(validationFields[field][0], { ns: "validations" });
      });
    }
  });
  return fields;
};

export const isErrorField = (error, field, errorMessage) => {
  if (!error) {
    return false;
  }

  if (errorMessage) {
    return error.graphQLErrors?.reduce((acc, { message }) => {
      if (message.includes(errorMessage)) acc = true;
      return acc;
    }, false);
  }

  const { graphQLErrors, formFieldErrors } = error;

  if (formFieldErrors && formFieldErrors.hasOwnProperty(field)) {
    return true;
  }

  if (!graphQLErrors || graphQLErrors.length <= 0) {
    return false;
  }

  const isInvalid = Boolean(parseGQLvalidation(error)[field]);

  return isInvalid;
};
