import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import {
  makeStyles,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@material-ui/icons";
import FormFooter from "../../components/FormFooter";
import { useLighthouseQuery } from "../../utils/lighthouseHooks";
import FetchTimezonesEnum from "../../graphql/users/fetchTimezonesEnum.graphql";
import cn from "classnames";
import { isEqual, cloneDeep } from "lodash";
import { isErrorField } from "../../utils/apollo";
import { LANGUAGE_ENUM } from "../../i18n/consts";

const checkPasswordConfirmation = (input) => {
  return input.password === input.password_confirmation;
};

const normalizeFormData = (form) => {
  const { lang, name, tz, password, password_confirmation } = form;

  return {
    lang,
    name,
    tz,
    password: password || "",
    password_confirmation: password_confirmation || "",
  };
};

// styles
const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
  generator: {
    textDecoration: "underline",
    cursor: "pointer",
    color: "#2196f3",
  },
  passwordContainer: {
    width: "100%",
  },
  passwordLabel: {
    paddingTop: 3,
  },
  eye: {
    padding: 0,
  },
  passwordError: {
    color: "red",
  },
}));

const ProfileForm = ({ user, error, loading, onSubmit }) => {
  const { t } = useTranslation("common");
  const css = useStyles();

  // state
  const [form, setForm] = useState(normalizeFormData(user));
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isPasswordsSame, setIsPasswordsSame] = useState(true);
  const [invalid, setInvalid] = useState([]);

  // graphql
  const timezonesQuery = useLighthouseQuery(FetchTimezonesEnum, {
    fetchPolicy: "cache-and-network",
  });

  // constants
  const isProfileDirty = !isEqual(normalizeFormData(user), form);

  // methods
  const validate = (form) => {
    const newInvalid = [];

    if (!form.name) {
      newInvalid.push("name");
    }

    setInvalid(newInvalid);

    return !newInvalid.length;
  };

  const handleChange = (name) => (event) => {
    const payload = {};

    setForm({
      ...form,
      [name]: event.target.value,
      ...payload,
    });
  };

  const generateRandomPassword = () => {
    const password = nanoid(18);
    setForm({ ...form, password_confirmation: password, password });
  };

  const handleSubmit = () => {
    const input = cloneDeep(form);

    if (!checkPasswordConfirmation(input)) {
      setIsPasswordsSame(false);
      return;
    }

    if (!input.password || input.password === "") {
      delete input.password;
      delete input.password_confirmation;
    }

    if (validate(input)) {
      onSubmit(input);
    }
  };

  return (
    <Paper className={css.formContainer}>
      <form autoComplete="off" noValidate>
        {/* Name */}
        <TextField
          disabled={loading}
          error={isErrorField(error, "name") || invalid.includes("name")}
          fullWidth
          inputProps={{ form: { autoComplete: "off" } }}
          label={t("name")}
          margin="normal"
          required
          value={form.name}
          onChange={handleChange("name")}
        />

        {/* Language */}
        <FormControl fullWidth className={css.formControl}>
          <InputLabel htmlFor="groupBy-multiple">{t("language")}</InputLabel>

          <Select
            data-testid="LanguageSelect"
            disabled={loading}
            fullWidth
            label={t("language")}
            value={form.lang}
            onChange={handleChange("lang")}
          >
            {LANGUAGE_ENUM.map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Timezone */}
        <FormControl fullWidth className={css.formControl}>
          <InputLabel htmlFor="groupBy-multiple">{t("timeZone")}</InputLabel>
          <Select disabled={loading} fullWidth label="tz" value={form.tz} onChange={handleChange("tz")}>
            {timezonesQuery?.loading === false
              ? (timezonesQuery?.data?.timezones || []).map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {/* Password */}
        <FormControl className={css.passwordContainer}>
          <InputLabel
            className={cn({
              [css.passwordError]: invalid.includes("password"),
              [css.passwordLabel]: !invalid.includes("password"),
            })}
            htmlFor="form-password"
          >
            {t("password")}
          </InputLabel>

          <Input
            id="form-password"
            aria-describedby="form-password-helper"
            disabled={loading}
            error={isErrorField(error, "password") || !isPasswordsSame || invalid.includes("password")}
            fullWidth
            margin="none"
            type={isShowPassword ? "text" : "password"}
            value={form.password}
            onChange={handleChange("password")}
            endAdornment={
              form.password !== "" && (
                <IconButton className={css.eye} onClick={() => setIsShowPassword(!isShowPassword)}>
                  {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              )
            }
          />
          <FormHelperText className={css.generator} id="form-password-helper" onClick={generateRandomPassword}>
            {`${t("generate")} ${t("password").toLowerCase()}`}
          </FormHelperText>
          <TextField
            fullWidth
            disabled={loading}
            error={
              isErrorField(error, "password_confirmation") ||
              !isPasswordsSame ||
              invalid.includes("password_confirmation")
            }
            label={t("passwordConfirmation")}
            value={form.password_confirmation}
            onChange={handleChange("password_confirmation")}
            type={isShowPassword ? "text" : "password"}
            margin="normal"
          />
        </FormControl>
      </form>

      <FormFooter backUrl={"/profile/preview"} isDirty={isProfileDirty} loading={loading} onSave={handleSubmit} />
    </Paper>
  );
};

ProfileForm.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  user: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ProfileForm;
