import { action, makeAutoObservable, set } from "mobx";
import { blue } from "@material-ui/core/colors";
import { configService } from "../configService";
import fetchUiTheme from "../graphql/Whitelabel/fetchUiTheme.graphql";

class UiTheme {
  systemName = `VEON DSP`;
  mainTitle = `VEON / DSP`;
  faviconUrl = `/favicon_default.ico`;
  colorPrimary = blue[400];
  buttonColor = `#00408F`;
  debugValue = `test`;
  colorTextDark = `rgba(0, 0, 0, 0.87)`;
  colorTextLight = `#fafafa`;
  logo = "/static/logo.png";

  @action fetchUiTheme() {
    return fetch(configService.API_LIGHTHOUSE_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: fetchUiTheme.loc.source.body,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const payload = JSON.parse(res?.data?.theme?.payload);
        if (typeof payload === `object` && payload !== null) {
          Object.keys(payload).forEach((key) => {
            set(this, key, payload[key]);
            console.log(key, payload[key]);
          });
        }
      })
      .catch(() => Promise.resolve()); // ignore errors
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export const uiThemeStore = new UiTheme();
