import React from "react";
import Layout from "./AdvertiserInnerLayout";

const AdvertiserInnerLayoutWrapper = (Component, breadcrumbParams) => (props) =>
  (
    <Layout breadcrumbParams={breadcrumbParams}>
      <Component {...props} />
    </Layout>
  );

export default AdvertiserInnerLayoutWrapper;
