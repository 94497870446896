import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const PageTitle = ({ title }) => {
  const { t } = useTranslation(`theme`);

  return (
    <Helmet>
      <title>{title ? `${title} — ${t(`mainTitle`)}` : t(`mainTitle`)}</title>
    </Helmet>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;
