// modules
import React from "react";

// components
import { makeStyles } from "@material-ui/core";
import CardLoader from "../placeholders/CardLoader";
import CardLoadedEmpty from "../placeholders/CardLoadedEmpty";
import ErrorSnackbar from "../ErrorSnackbar";

// utils
import { observer } from "mobx-react-lite";

// styles
const useStyles = makeStyles(() => ({
  loader: {
    position: "absolute",
    top: 64,
    left: 0,
    right: 0,
  },
  agencyBtn: {
    position: "absolute",
  },
}));

const Boundary = observer((props) => {
  const { children, error, data, loading, warnLabel } = props;
  const css = useStyles();

  return (
    <>
      {error && <ErrorSnackbar error={true} />}
      {loading && <CardLoader className={css.loader} />}
      {(!loading && !data && <CardLoadedEmpty>{warnLabel}</CardLoadedEmpty>) || children}
    </>
  );
});

Boundary.defaultProps = {
  children: null,
  error: false,
  data: true,
  loading: false,
  warnLabel: "NO DATA",
};

export default Boundary;
