import React, { Suspense, useState } from "react";
import { Box, FormControlLabel, LinearProgress, makeStyles, Switch } from "@material-ui/core";
import cn from "classnames";
import {
  Drawer,
  AppBar,
  Menu as MenuRoot,
  MenuItem,
  Toolbar,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import PermIdentity from "@material-ui/icons/PermIdentity";
import { Link, useHistory } from "react-router-dom";
import { Icon } from "@material-ui/core";
import currentUser from "../../graphql/auth/currentUser.graphql";
import logoutMutation from "../../graphql/auth/logoutMutation.graphql";
import { useAuth } from "../../hoc/withAuth";
import Menu from "../Menu";
import { PREVIEW } from "../User/ProfileEditPage";
import { ThemeChangeContext } from "../../utils/themeContext";
import { useTranslation } from "react-i18next";
import userContext from "../../store/userContext";
import { useLighthouseMutation } from "../../utils/lighthouseHooks";
import { observer } from "mobx-react-lite";
import { lighthouseClient } from "../../apollo/LighthouseClient";
import { clearFilters } from "../../utils/hooks/useFilters";
import ChangeLanguageButton from "../../components/ChangeLanguageButton/ChangeLanguageButton";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: `100vh`,
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBar: {
    position: "absolute",
    zIndex: theme.zIndex.modal,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  btnIcon: {
    marginRight: theme.spacing(),
  },
  menuRoot: {
    zIndex: theme.zIndex.modal + 2,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    zIndex: 1202,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&::-webkit-scrollbar": {
      width: 4,
      height: 4,
    },
    "&::-webkit-scrollbar-button": {
      display: `none`,
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.grey["100"],
      borderRadius: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey.A200,
      borderRadius: 1,
    },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 24,
    marginTop: 64,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 8,
      height: 6,
    },
    "&::-webkit-scrollbar-button": {
      display: `none`,
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.grey["100"],
      borderRadius: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey.A200,
      borderRadius: 1,
    },
  },
  progress: {
    width: "100%",
    marginTop: 64,
  },
  contentInner: {
    marginBottom: 12,
  },
  flex: {
    flex: 1,
  },
  accountMenu: {
    marginRight: 24,
    display: "flex",
  },
  noStylesLink: {
    textDecoration: "none",
    color: "black",
  },
}));

const Layout = observer(({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = useAuth();
  const css = useStyles();
  const { t } = useTranslation(["theme", "common"]);
  const history = useHistory();

  const [logout, logoutResponse] = useLighthouseMutation(logoutMutation, { shouldNotifyOnSuccess: false });
  const isLoading = logoutResponse?.loading === true;

  const handleDrawerOpen = () => setIsOpen(true);
  const handleDrawerClose = () => setIsOpen(false);
  const handleOpenDebugPage = () => history.push(`/debug`);
  const handleClickProfile = (event) => {
    setIsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => setIsMenuOpen(false);
  const handleLogout = () => {
    const catchErrors = () => lighthouseClient.writeQuery({ query: currentUser, data: { user: null } });
    logout()
      .then((result) => {
        userContext.resetAgency();
        if (result?.data?.logout === true)
          auth
            .refetch()
            .then((data) => {
              clearFilters();

              if (!data?.user) {
                history.push(`/auth`);
              }
            })
            .catch(catchErrors);
      })
      .catch(catchErrors);
  };

  const divStyle = {
    color: "blue",
    background: "left center no-repeat url(" + t(`logo`) + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    lineHeight: "2.6",
    textIndent: "-1000em",
  };

  return (
    <div className={css.root}>
      <div className={css.appFrame}>
        <AppBar id="header" className={cn(css.appBar, { [css.appBarShift]: isOpen })}>
          <Toolbar disableGutters={!isOpen}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              data-testid="openSidebar"
              onClick={handleDrawerOpen}
              className={cn(css.menuButton, { [css.hide]: isOpen })}
            >
              <Icon>
                <MenuIcon />
              </Icon>
            </IconButton>

            <Typography variant="h6" color="inherit" style={divStyle} noWrap className={css.flex}>
              {t(`systemName`)}
            </Typography>
            <ThemeChangeContext.Consumer>
              {({ toggleColors, isInitialColors, canSwitch }) =>
                canSwitch && (
                  <>
                    <Box component={`span`} mr={2}>
                      <Button to={`/debug`} onClick={handleOpenDebugPage}>
                        Debug
                      </Button>
                    </Box>
                    <FormControlLabel
                      control={<Switch checked={isInitialColors} onChange={toggleColors} />}
                      label="Switch theme"
                    />
                  </>
                )
              }
            </ThemeChangeContext.Consumer>

            {auth.isLogged === true ? (
              <div className={!isOpen ? css.accountMenu : css.accountMenu}>
                <Button
                  data-testid="openUserMenu"
                  color="inherit"
                  aria-owns={isMenuOpen ? "personal-menu" : null}
                  aria-haspopup="true"
                  onClick={handleClickProfile}
                >
                  <PersonIcon className={css.btnIcon} /> {auth?.user?.agency_name} / {auth?.user?.email}
                </Button>
                <MenuRoot
                  id="personal-menu"
                  data-testid="personalMenu"
                  className={css.menuRoot}
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleRequestClose}
                >
                  <Link data-testid="personalMenuSettingsBtn" className={css.noStylesLink} to={`/profile/${PREVIEW}`}>
                    <MenuItem>{t("My settings", { ns: "common" })}</MenuItem>
                  </Link>
                  <MenuItem data-testid="personalMenuLogoutBtn" disabled={isLoading} onClick={handleLogout}>
                    {t("Logout", { ns: "common" })}
                  </MenuItem>
                </MenuRoot>
              </div>
            ) : (
              <Button color="inherit" component={Link} to={"/auth"} className={cn({ [css.shiftMargin]: !isOpen })}>
                <PermIdentity /> Вход
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          id="sidebar"
          data-testid="sidebar"
          classes={{
            paper: cn(css.drawerPaper, { [css.drawerPaperClose]: !isOpen }),
          }}
          open={isOpen}
        >
          <div className={css.drawerInner}>
            <div className={css.drawerHeader}>
              <ChangeLanguageButton />
              <IconButton onClick={handleDrawerClose}>
                <Icon>
                  <ChevronLeftIcon />
                </Icon>
              </IconButton>
            </div>
            <Divider />
            <Menu isOpen={isOpen} />
          </div>
        </Drawer>
        <Suspense fallback={<LinearProgress color="primary" className={css.progress} />}>
          <main id="dsp-main" className={css.content}>
            <div className={css.contentInner}>{children}</div>
          </main>
        </Suspense>
      </div>
    </div>
  );
});

export default Layout;
