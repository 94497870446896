import React from "react";
import Layout from "./AdvertiserLayout";

const AdvertiserLayoutWrapper = (Component) => (props) =>
  (
    <Layout>
      <Component {...props} />
    </Layout>
  );

export default AdvertiserLayoutWrapper;
