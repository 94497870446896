import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Card, CardContent } from "@material-ui/core";
import BoxImage from "../../assets/images/box.svg";

const useStyles = makeStyles(() => ({
  card: {
    textAlign: "center",
    padding: "6vh",
    fontSize: 30,
    fontWeight: "bold",
    fontFamily: "monospace",
  },
  container: {
    height: "50vh",
    textAlign: "center",
    padding: 30,
  },
  box: {
    height: 200,
  },
}));

const CardLoadedEmpty = ({ children }) => {
  const css = useStyles();
  return (
    <Card className={css.container}>
      <img className={css.box} src={BoxImage} alt="box" />
      <CardContent className={css.card}>{children}</CardContent>
    </Card>
  );
};

CardLoadedEmpty.propTypes = {
  children: PropTypes.node,
};

CardLoadedEmpty.defaultProps = {
  children: "No data to display",
};

export default CardLoadedEmpty;
