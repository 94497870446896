import ApolloBoost from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { customHistory } from "../routing/customHistory";
import configService from "../configService";

const cache = new InMemoryCache({
  addTypename: false,
});

const onError = (errors) => {
  const { networkError = {}, graphQLErrors = [] } = errors;
  const isAccessDenied = graphQLErrors[0]?.extensions?.machine_code === "ACCESS_DENIED";
  if (networkError.statusCode === 401 || isAccessDenied) {
    customHistory.push("/403");
  }
};

export const lighthouseClient = new ApolloBoost({
  uri: () => configService.API_LIGHTHOUSE_ENDPOINT,
  request: (operation) => {
    operation.setContext({
      headers: {
        contentType: "application/json",
      },
    });
  },
  credentials: "include",
  cache,
  onError,
  clientState: withClientState({ cache }),
});
