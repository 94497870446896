import React from "react";
import Layout from "./Layout";

const LayoutWrapper = (Component) => (props) =>
  (
    <Layout>
      <Component {...props} />
    </Layout>
  );

export default LayoutWrapper;
