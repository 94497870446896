// modules
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as HookProvider } from "@apollo/react-hooks";

// components
import App from "./App";
import * as Sentry from "@sentry/react";
import { LinearProgress } from "@material-ui/core";

// utils
import configService from "./configService";
import { customHistory } from "./routing/customHistory";
import { ThemeProvider } from "./utils/themeContext";
import { uiThemeStore } from "./store/uiTheme";
import i18nInit from "./i18n";
import { lighthouseClient } from "./apollo/LighthouseClient";

import "moment/locale/ru";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.scss";

const SentryFallBack = () => (
  <div
    style={{
      display: `flex`,
      justifyContent: `center`,
      alignItems: `center`,
      width: `100%`,
      height: `100vh`,
    }}
  >
    <div style={{ textAlign: `center` }}>
      An error has occurred
      <br /> Возникла ошибка
    </div>
  </div>
);

configService
  .init()
  .catch(() => {
    // catch only configService.init(). Other exceptions catched by Sentry and boundaries
    document.body.innerHTML = `
<style>body{display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;}</style>
<div style="text-align: center">Ошибка инициализации приложения. Обратитесь к администратору.<br/>
Cannot initialize application. Contact the administrator.</div>
`;
  })
  .then(() => uiThemeStore.fetchUiTheme())
  .then(() => i18nInit)
  .then(() => {
    Sentry.init({
      dsn: `https://3cd5be42092c4909b06ca05d99dbef71@o344626.ingest.sentry.io/5736389`,
      release: (process.env.REACT_APP_SENTRY_RELEASE || "").split('"').join(""),
    });

    ReactDOM.render(
      <Sentry.ErrorBoundary fallback={<SentryFallBack />} showDialog>
        <ThemeProvider>
          <Suspense fallback={<LinearProgress color="primary" />}>
            <Router history={customHistory}>
              <HookProvider client={lighthouseClient}>
                <ApolloProvider client={lighthouseClient}>
                  <App />
                </ApolloProvider>
              </HookProvider>
            </Router>
          </Suspense>
        </ThemeProvider>
      </Sentry.ErrorBoundary>,
      document.querySelector("#root")
    );
  });
