import React, { Suspense } from "react";
import { Typography, Breadcrumbs, LinearProgress } from "@material-ui/core";
import fetchOneAdvertiser from "../../graphql/advertisers/fetchOneAdvertiser.graphql";
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLighthouseQuery } from "../../utils/lighthouseHooks";
import useRoutesNames from "../../routing/useRoutesNames";
import { useTranslation } from "react-i18next";
import useI18nContext from "../../utils/hooks/useI18nContext";

const ADV_NAME_PATTERN = "#advertiserName#";

const AdvertiserInnerLayout = ({ children, breadcrumbParams }) => {
  const params = useParams();
  const advertiserId = params?.advertiserId;
  const { t } = useTranslation(["common"]);
  const context = useI18nContext();
  const { paths } = useRoutesNames();

  const { data, loading } = useLighthouseQuery(fetchOneAdvertiser, {
    variables: {
      id: advertiserId,
      skip: !advertiserId,
    },
  });

  const [advertiserTitle, subUrl, pageTitle] = breadcrumbParams;

  const agencyName = loading ? "" : data?.advertiser?.agency_name;
  const advertiserName = loading
    ? `${t("advertiser", { ns: "common", context })} #${advertiserId}`
    : data?.advertiser?.name;

  return (
    <Suspense fallback={<LinearProgress color="primary" />}>
      <div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
          <Link color="inherit" component={RouterLink} to={`${paths.advertisersBase}/list`}>
            {t("advertisers", { ns: "common", context })}
          </Link>
          {!loading && <Typography color="textPrimary">{agencyName}</Typography>}
          <Link color="inherit" component={RouterLink} to={`${paths.advertisersBase}/${advertiserId}/${subUrl}`}>
            {advertiserTitle.replace(ADV_NAME_PATTERN, advertiserName)}
          </Link>
          <Typography color="textPrimary">{pageTitle}</Typography>
        </Breadcrumbs>
        {children}
      </div>
    </Suspense>
  );
};

export default AdvertiserInnerLayout;
