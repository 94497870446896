export const USING_AGENCY = "using_agency";

/**
 *
 * @param {Storage} [storage]
 * @returns {{ get: (key: string) => any, set: (key: string, value: any) => void, clear: (itemsToClear?: string[]) => void }}
 */
function storageUtils(storage) {
  const store = storage || sessionStorage;

  const set = (key, itemValue) => {
    try {
      const value = JSON.stringify(itemValue || "");
      store.setItem(key, value);
    } catch (e) {
      store.setItem(key, "");
    }
  };

  const get = (key) => {
    const value = store.getItem(key);
    try {
      if (value) return JSON.parse(value);
      return "";
    } catch (e) {
      return "";
    }
  };

  const clear = (itemsToClear = []) => {
    if (itemsToClear?.length) itemsToClear.forEach((key) => store.removeItem(key));
    else store.clear();
  };

  return { set, get, clear };
}

export default storageUtils;
