import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { DEFAULT_LANGUAGE } from "./consts";
import { keys } from "mobx";
import { uiThemeStore } from "../store/uiTheme";
import { autorun } from "mobx";

const LanguageSuffixes = [`en`, `ru`];

const recomputeThemeNamespace = () => {
  const baseKeys = keys(uiThemeStore).filter((k) => !RegExp(`_(${LanguageSuffixes.join("|")})$`).test(k)); // keys without translation suffix
  LanguageSuffixes.forEach((languageSuffix) => {
    // populate ns for each language
    baseKeys.forEach((key) => {
      // do for each base key
      i18n.addResource(
        languageSuffix,
        `theme`,
        key,
        uiThemeStore[`${key}_${languageSuffix}`] || uiThemeStore[key] // example: populate systemName key with `systemName_en` || `systemName` from store
      );
    });
  });
};

const instance = i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: [`common`],
    defaultNS: `common`,
    fallbackLng: DEFAULT_LANGUAGE,
    lng: DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      format: function (value, format) {
        if (format === "uppercase") return value.toUpperCase();
        if (format === "capitalize") return `${value[0].toUpperCase()}${value.slice(1)}`;
        return value;
      },
    },
  })
  .then(() => {
    recomputeThemeNamespace();
    // subscribe to uiThemeStore changes
    autorun(() => {
      recomputeThemeNamespace();
    });
    return Promise.resolve();
  });

export default instance;
