import { useLazyQuery } from "@apollo/react-hooks";
import useGraphqlResponseParser from "../components/NotificationCenter/useGraphqlResponseParser";

function useLazyQueryWithNotification(mutation, opts) {
  const {
    entity,
    operationName = "get",
    shouldNotifyOnError = true,
    shouldNotifyOnSuccess = false,
    ...options
  } = opts || {};

  const lazyQuery = useLazyQuery(mutation, options);

  useGraphqlResponseParser({
    response: lazyQuery[1],
    entity,
    operationName,
    shouldNotifyOnError,
    shouldNotifyOnSuccess,
  });

  return lazyQuery;
}

export default useLazyQueryWithNotification;
