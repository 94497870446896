// modules
import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

// components
import CardLoader from "../components/placeholders/CardLoader";
import PageTitle from "../components/PageTitle/PageTitle";

// hooks
import { useAuth } from "../hoc/withAuth";

// utils
import queryString from "query-string";

const PrivateRoute = (props) => {
  const { component: Component, isGranted, computedMatch, title, ...otherProps } = props;

  const auth = useAuth();

  const urlQuery = queryString.stringify({ redirect: computedMatch.url + window.location.search });

  return (
    <Route
      {...otherProps}
      computedMatch={computedMatch}
      render={(props) => {
        if (auth.loading === true) {
          return <CardLoader />;
        }

        if (isGranted(auth)) {
          return (
            <>
              <PageTitle title={title} />
              <Component {...props} />
            </>
          );
        }

        return <Redirect to={`/auth?${urlQuery}`} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  isGranted: PropTypes.func.isRequired,
  computedMatch: PropTypes.shape({
    params: PropTypes.object,
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  title: PropTypes.string,
};

PrivateRoute.defaultProps = {
  isGranted: () => true,
};

export default PrivateRoute;
