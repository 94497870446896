// modules
import React, { useState } from "react";

// components
import { makeStyles, Grid } from "@material-ui/core";
import Notification from "./Notification";

// styles
const useStyles = makeStyles(() => ({
  container: {
    position: "fixed",
    right: 0,
    top: 80,
    width: 350,
    paddingRight: 10,
    zIndex: 10000,
  },
  notificationChild: {
    margin: "5px 0",
  },
}));

// static
const variants = ["error", "warning", "info", "success"].reduce((obj, color) => {
  return { ...obj, [color]: color };
}, {});

// context
export const NotificationContext = React.createContext({});

const NotificationCenter = (props) => {
  const { children } = props;

  const css = useStyles();

  // state
  const [notifications, setNotifications] = useState([]);

  // methods
  const removeNotification = (id) => {
    setNotifications((old) => old.filter((item) => item.id !== id));
  };

  const pushNotification = (newNotification) => {
    const id = new Date().valueOf();
    setNotifications((old) => [...old, { ...newNotification, id }]);
  };

  return (
    <NotificationContext.Provider value={{ pushNotification }}>
      {children}

      <Grid className={css.container} container justify="flex-end">
        {notifications.map((notification) => {
          const { children, id, variant, title, body } = notification;
          const color = variants[variant] ?? "info";

          return (
            <Notification
              key={id}
              color={color}
              nestedNotifications={children}
              severity={color}
              onRemove={() => removeNotification(id)}
            >
              <strong>{title}</strong>
              {!children && <div>{body}</div>}
            </Notification>
          );
        })}
      </Grid>
    </NotificationContext.Provider>
  );
};

export default NotificationCenter;
