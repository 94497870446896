import { makeAutoObservable, get, set, action, computed } from "mobx";
import storageUtils, { USING_AGENCY } from "../utils/storage";

class UserContext {
  constructor() {
    makeAutoObservable(this);
  }

  user = null;
  agency = storageUtils().get(USING_AGENCY); // persist in localstorage

  @computed get agencyId() {
    return this.agency?.id;
  }
  getAgencyId = () => get(this, `agencyId`);
  @action setAgency(value) {
    storageUtils().set(USING_AGENCY, value); // persist in localstorage
    if (!value) {
      storageUtils().clear([USING_AGENCY]);
    }
    set(this, `agency`, value);
  }
  @action resetAgency() {
    this.setAgency("");
  }

  getUser = () => get(this, `user`);
  @action setUser(value) {
    set(this, `user`, value);
  }
  @action resetUser() {
    this.setUser(null);
  }
}

export const userContext = new UserContext();
export default userContext;
