import { createBrowserHistory } from "history";
export const customHistory = createBrowserHistory();

let currentLocation = window.location.pathname + window.location.search + window.location.hash;

customHistory.block((location, action) => {
  const nextLocation = location.pathname + location.search + location.hash;
  const isSameLocation = currentLocation === nextLocation;

  if (action === "PUSH" && isSameLocation) {
    return false;
  }

  currentLocation = nextLocation;

  return true;
});
