import { useThrottleFn } from "ahooks";

import useQueryWithNotification from "../apollo/useQueryWithNotification";
import useLazyQueryWithNotification from "../apollo/useLazyQueryWithNotification";
import useMutationWithNotification from "../apollo/useMutationWithNotification";

import { lighthouseClient } from "../apollo/LighthouseClient";
import userContext from "../store/userContext";

export const useLighthouseQuery = (query, params = {}) => {
  const config = {
    client: lighthouseClient,
    fetchPolicy: "network-only",
    ...params,
  };

  if (params.useAgency) {
    config.variables = {
      using_agency: userContext.getAgencyId(),
      ...params.variables,
    };
  }

  return useQueryWithNotification(query, config);
};

export const useLighthouseLazyQuery = (query, { wait, ...params } = {}) => {
  const config = {
    client: lighthouseClient,
    fetchPolicy: "network-only",
    ...params,
  };

  if (params.useAgency) {
    config.variables = {
      using_agency: userContext.getAgencyId(),
      ...params.variables,
    };
  }

  const [func, response] = useLazyQueryWithNotification(query, config);

  if (!wait) {
    return [func, response];
  }

  const { run: throttled } = useThrottleFn(func, { wait, trailing: false });

  return [throttled, response];
};

export const useLighthouseMutation = (mutation, params) =>
  useMutationWithNotification(mutation, { client: lighthouseClient, ...params });
