import React from "react";
import Layout from "./SitesLayout";

const SiteLayoutWrapper = (Component) => (props) =>
  (
    <Layout componentProps={{ ...props }}>
      <Component {...props} />
    </Layout>
  );

export default SiteLayoutWrapper;
