import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { NotificationContext } from ".";
import { parseGQLvalidation } from "../../utils/apollo";
import { useTranslation } from "react-i18next";

const parseNonUserFriendlyErrors = (error, title) => {
  const { extensions } = error?.graphQLErrors?.[0] || {};
  return {
    title,
    body: `${extensions?.category?.toUpperCase?.() || "unknown"} error ${error?.graphQLErrors?.[0]?.message || ``}`,
    variant: "error",
    children:
      error?.graphQLErrors?.length > 1 ? error?.graphQLErrors.slice(1).map((err) => ({ title: err?.message })) : null,
  };
};

const parseValidationErrors = (error) => {
  const errorsObj = parseGQLvalidation(error);
  return Object.keys(errorsObj).map((key) => ({ title: errorsObj[key] }));
};

const useGraphqlResponseParser = ({ response, entity, operationName, shouldNotifyOnError, shouldNotifyOnSuccess }) => {
  const { t } = useTranslation(["common", "validations"]);
  const { data, loading, error } = response;
  const { pushNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (!error || !shouldNotifyOnError) return;
    const validationErrors = parseValidationErrors(error);
    const hasValidationErrors = Boolean(validationErrors?.length);
    const children = hasValidationErrors && validationErrors;
    const message = error?.graphQLErrors?.[0]?.message
      ? t(error.graphQLErrors[0].message, { ns: "validations" })
      : t("serverError", { ns: "common" });
    const title = t(hasValidationErrors ? "validationErrors" : "serverError", { ns: "common" });

    if (hasValidationErrors) {
      pushNotification({ title, variant: "error", body: message, children });
    } else {
      pushNotification(parseNonUserFriendlyErrors(error, t("serverError", { ns: "common" })));
    }
  }, [error]);

  useEffect(() => {
    if (!shouldNotifyOnSuccess) return;
    const label = entity || t(`data`);
    const upperEntity = `${label[0].toUpperCase()}${label.slice(1)}`;
    if (data && !loading && !error)
      pushNotification({ variant: "success", title: `${upperEntity} ${operationName || t(`saved`)}` });
  }, [data, loading, error]);

  return null;
};

useGraphqlResponseParser.propTypes = {
  response: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
    loading: PropTypes.bool,
    error: PropTypes.objectOf(PropTypes.any),
  }),
  entity: PropTypes.string,
  operationName: PropTypes.string,
};

useGraphqlResponseParser.defaultProps = {
  response: {
    data: null,
    loading: false,
    error: null,
  },
  entity: "",
  operationName: "",
};

export default useGraphqlResponseParser;
