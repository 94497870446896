import React from "react";
import { Typography, Breadcrumbs } from "@material-ui/core";
import fetchOneAdvertiser from "../../graphql/advertisers/fetchOneAdvertiser.graphql";
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLighthouseQuery } from "../../utils/lighthouseHooks";
import useRoutesNames from "../../routing/useRoutesNames";
import { useTranslation } from "react-i18next";
import useI18nContext from "../../utils/hooks/useI18nContext";

const ADV_NAME_PATTERN = "#advertiserName#";

const AdvertiserLayout = ({ children }) => {
  const params = useParams();
  const { t } = useTranslation(["common"]);
  const context = useI18nContext();
  const advertiserId = params?.advertiserId;
  const { data, loading } = useLighthouseQuery(fetchOneAdvertiser, {
    variables: {
      id: advertiserId,
      skip: !advertiserId,
    },
  });
  const { paths } = useRoutesNames();

  const agencyName = loading ? "" : data?.advertiser?.agency_name;
  const advertiserName = loading
    ? `${t("advertiser", { ns: "common", context })} #${advertiserId}`
    : data?.advertiser?.name;

  const pageTitle = children.props.hasOwnProperty("pageTitle") ? children.props.pageTitle : ADV_NAME_PATTERN;

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
        <Link color="inherit" component={RouterLink} to={`${paths.advertisersBase}/list`}>
          {t("advertisers", { ns: "common", context })}
        </Link>
        {!loading && <Typography color="textPrimary">{agencyName}</Typography>}
        <Typography color="textPrimary">{pageTitle.replace(ADV_NAME_PATTERN, advertiserName)}</Typography>
      </Breadcrumbs>
      {children}
    </div>
  );
};

export default AdvertiserLayout;
