import React from "react";
import { Typography } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { graphql } from "react-apollo";
import { Link as RouterLink, withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { compose } from "react-apollo";
import compaignsGroupAdveriser from "../../graphql/breadcrumbs/compaignsGroupAdvertiser.graphql";
import compaignsAdvertiser from "../../graphql/breadcrumbs/compaignsAdvertiser.graphql";
import advertiser from "../../graphql/breadcrumbs/advertiser.graphql";
import appsAdvertiser from "../../graphql/breadcrumbs/appsAdvertiser.graphql";
import domainsAdvertiser from "../../graphql/breadcrumbs/domainsAdvertiser.graphql";
import publishersAdvertiser from "../../graphql/breadcrumbs/publishersAdvertiser.graphql";
import siteListAdvertiser from "../../graphql/breadcrumbs/siteListAdvertiser.graphql";
import creativesAdvertiser from "../../graphql/breadcrumbs/creativesAdvertiser.graphql";
import containerSite from "../../graphql/breadcrumbs/containerSite.graphql";
import containersSite from "../../graphql/breadcrumbs/containersSite.graphql";
import pixelContainerSite from "../../graphql/breadcrumbs/pixelContainerSite.graphql";
import { lighthouseClient } from "../../apollo/LighthouseClient";
import { useTranslation } from "react-i18next";
import useI18nContext from "../../utils/hooks/useI18nContext";
import { capitalize } from "lodash/string";

export const optionsType = Object.freeze({
  compaignsGroup: "compaignsGroup",
  compaings: "compaings",
  compaingCreate: "compaingCreate",
  compaingCopy: "compaingCopy",
  advertiser: "advertiser",
  appsAdvertiser: "appsAdvertiser",
  domainsAdvertiser: "domainsAdvertiser",
  publishersAdvertiser: "publishersAdvertiser",
  siteListAdvertiser: "siteListAdvertiser",
  html5CreativesAdvertiser: "html5CreativesAdvertiser",
  thirdPartyBannerCreativesAdvertiser: "thirdPartyBannerCreativesAdvertiser",
  thirdPartyVideoCreativesAdvertiser: "thirdPartyVideoCreativesAdvertiser",
  thirdPartyAudioCreativesAdvertiser: "thirdPartyAudioCreativesAdvertiser",
  videoCreativesAdvertiser: "videoCreativesAdvertiser",
  containerSite: "containerSite",
  containersSite: "containersSite",
  pixelsContainerSite: "pixelsContainerSite",
  pixelCreateContainerSite: "pixelCreateContainerSite",
  pixelContainerSite: "pixelContainerSite",
});

const getDeepSerchParams = (path, data) => {
  const nestedLevelName = path.pop().split("|")[0];
  if (!path.length) {
    return data[nestedLevelName] || 0;
  }
  return getDeepSerchParams(path, data[nestedLevelName]);
};

const firstLatterToUpperCase = (text) => text.replace(/^\w/i, (char) => char.toUpperCase());

const optionsFactory = (optionsTypeName, { t, context }) => {
  const advertisersUrlPath = context === "retail" ? "brands" : "advertisers";
  switch (optionsTypeName) {
    case optionsType.compaignsGroup: {
      return {
        query: compaignsGroupAdveriser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.groupId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["advertiser/|agency_name"],
            itemInUrlPath: [advertisersUrlPath],
            urlPathVariableName: ["advertiserId"],
            noLink: true,
          },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "Campaigns without group",
            variableName: ["advertiser", "campaignsGroup"],
            itemInUrlPath: [],
            urlPathVariableName: ["advertiserId", "groupId"],
          },
        ],
      };
    }
    case optionsType.compaings: {
      return {
        query: compaignsAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["advertiser/|agency_name"],
            itemInUrlPath: [advertisersUrlPath],
            urlPathVariableName: ["advertiserId"],
            noLink: true,
          },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "[No group]",
            variableName: ["advertiser", "campaign/group_id|group_name"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "campaigns"],
            urlPathVariableName: ["advertiserId", "groupId"],
          },
          {
            defaultName: t("campaign"),
            variableName: ["campaign"],
            itemInUrlPath: [],
            urlPathVariableName: ["campaign"],
          },
        ],
      };
    }
    case optionsType.compaingCreate: {
      return {
        query: compaignsGroupAdveriser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.groupId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["advertiser/|agency_name"],
            itemInUrlPath: [advertisersUrlPath],
            urlPathVariableName: ["advertiserId"],
            noLink: true,
          },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: t("campaignsWithoutGroup", { ns: "campaign" }),
            variableName: ["advertiser", "campaignsGroup"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "campaigns"],
            urlPathVariableName: ["advertiserId", "groupId"],
          },
          {
            defaultName: t("createNewCampaign", { ns: "campaign" }),
            variableName: [],
            itemInUrlPath: [],
            urlPathVariableName: [],
          },
        ],
      };
    }
    case optionsType.compaingCopy: {
      return {
        query: compaignsAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["advertiser/|agency_name"],
            itemInUrlPath: [advertisersUrlPath],
            urlPathVariableName: ["advertiserId"],
            noLink: true,
          },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: t("[No group]", { ns: "campaign" }),
            variableName: ["advertiser", "campaign/group_id|group_name"],
            itemInUrlPath: [advertisersUrlPath, "campaigns_group", "campaigns"],
            urlPathVariableName: ["advertiserId", "groupId"],
          },
          {
            defaultName: t("campaign"),
            variableName: ["advertiser", "campaign"],
            itemInUrlPath: [advertisersUrlPath, "campaigns", "edit"],
            urlPathVariableName: ["advertiserId", "id"],
          },
          {
            defaultName: t("copy_btn", { ns: "campaign" }),
            variableName: [],
            itemInUrlPath: [],
            urlPathVariableName: [],
          },
        ],
      };
    }
    case optionsType.advertiser: {
      return {
        query: advertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "apps_list", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          { defaultName: `${t(`createNew`)} ${t("appsListTitle", { list: t("list") })}` },
        ],
      };
    }
    case optionsType.appsAdvertiser: {
      return {
        query: appsAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "apps_list", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: capitalize(t("listsOneEntity.apps", { ns: "campaign" })),
            variableName: ["appsList"],
            itemInUrlPath: [],
            urlPathVariableName: ["appsList"],
          },
        ],
      };
    }
    case optionsType.domainsAdvertiser: {
      return {
        query: domainsAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "domains_list", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: capitalize(t("listsOneEntity.domains", { ns: "campaign" })),
            variableName: ["domainsList"],
            itemInUrlPath: [],
            urlPathVariableName: ["domainsList"],
          },
        ],
      };
    }
    case optionsType.publishersAdvertiser: {
      return {
        query: publishersAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "publishers_list", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: capitalize(t("listsOneEntity.publishers", { ns: "campaign" })),
            variableName: ["publishersList"],
            itemInUrlPath: [],
            urlPathVariableName: ["publishersList"],
          },
        ],
      };
    }
    case optionsType.siteListAdvertiser: {
      return {
        query: siteListAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "sites_list", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: capitalize(t("listsOneEntity.sites", { ns: "campaign" })),
            variableName: ["sitesList"],
            itemInUrlPath: [],
            urlPathVariableName: ["id"],
          },
        ],
      };
    }
    case optionsType.html5CreativesAdvertiser: {
      return {
        query: creativesAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "creatives", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "Html5Creative",
            variableName: ["html5Creative"],
            itemInUrlPath: [],
            urlPathVariableName: ["id"],
          },
        ],
      };
    }
    case optionsType.thirdPartyBannerCreativesAdvertiser: {
      return {
        query: creativesAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "creatives", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "ThirdPartyBannerCreative",
            variableName: ["thirdPartyBannerCreative"],
            itemInUrlPath: [],
            urlPathVariableName: ["id"],
          },
        ],
      };
    }
    case optionsType.thirdPartyVideoCreativesAdvertiser: {
      return {
        query: creativesAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "creatives", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "ThirdPartyVideoCreative",
            variableName: ["thirdPartyVideoCreative"],
            itemInUrlPath: [],
            urlPathVariableName: ["id"],
          },
        ],
      };
    }
    case optionsType.thirdPartyAudioCreativesAdvertiser: {
      return {
        query: creativesAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "creatives", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "ThirdPartyAudioCreative",
            variableName: ["thirdPartyAudioCreative"],
            itemInUrlPath: [],
            urlPathVariableName: ["id"],
          },
        ],
      };
    }
    case optionsType.videoCreativesAdvertiser: {
      return {
        query: creativesAdvertiser,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.advertiserId || 0,
              idB: props.match.params.id || 0,
            },
          }),
        },
        breadcrumbsPath: [
          { defaultName: t("advertisers", { context }), itemInUrlPath: [advertisersUrlPath, "list"] },
          {
            defaultName: t("advertiser", { context }),
            variableName: ["advertiser"],
            itemInUrlPath: [advertisersUrlPath, "creatives", "list"],
            urlPathVariableName: ["advertiserId"],
          },
          {
            defaultName: "VideoCreative",
            variableName: ["videoCreative"],
            itemInUrlPath: [],
            urlPathVariableName: ["id"],
          },
        ],
      };
    }
    case optionsType.containerSite: {
      return {
        query: containerSite,
        queryOptions: {
          options: ({
            match: {
              params: { siteId },
            },
          }) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: siteId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: "Sites", itemInUrlPath: ["sites", "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["oneSite/|agency_name"],
            itemInUrlPath: ["sites"],
            urlPathVariableName: ["siteId"],
            noLink: true,
          },
          {
            defaultName: capitalize(t("listsOneEntity.sites", { ns: "campaign" })),
            variableName: ["oneSite"],
            itemInUrlPath: ["sites", "containers", "list"],
            urlPathVariableName: ["siteId"],
          },
          { defaultName: t("container") },
        ],
      };
    }
    case optionsType.containersSite: {
      return {
        query: containersSite,
        queryOptions: {
          options: ({
            match: {
              params: { siteId, containerId },
            },
          }) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: siteId || 0,
              idB: containerId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: capitalize(t("listsMultiple.sites", { ns: "campaign" })), itemInUrlPath: ["sites", "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["oneSite/|agency_name"],
            itemInUrlPath: ["sites"],
            urlPathVariableName: ["siteId"],
            noLink: true,
          },
          {
            defaultName: capitalize(t("listsOneEntity.sites", { ns: "campaign" })),
            variableName: ["oneSite"],
            itemInUrlPath: ["sites", "containers", "list"],
            urlPathVariableName: ["siteId"],
          },
          {
            defaultName: t("container"),
            variableName: ["oneContainer"],
            itemInUrlPath: [],
            urlPathVariableName: ["siteId"],
          },
        ],
      };
    }
    case optionsType.pixelsContainerSite: {
      return {
        query: containersSite,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.siteId || 0,
              idB: props.match.params.containerId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: capitalize(t("listsMultiple.sites", { ns: "campaign" })), itemInUrlPath: ["sites", "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["oneSite/|agency_name"],
            itemInUrlPath: ["sites"],
            urlPathVariableName: ["siteId"],
            noLink: true,
          },
          {
            defaultName: capitalize(t("listsOneEntity.sites", { ns: "campaign" })),
            variableName: ["oneSite"],
            itemInUrlPath: ["sites", "containers", "list"],
            urlPathVariableName: ["siteId"],
          },
          {
            defaultName: t("container"),
            variableName: ["oneContainer"],
            itemInUrlPath: [],
            urlPathVariableName: ["siteId"],
          },
        ],
      };
    }
    case optionsType.pixelCreateContainerSite: {
      return {
        query: containersSite,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.siteId || 0,
              idB: props.match.params.containerId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: capitalize(t("listsMultiple.sites", { ns: "campaign" })), itemInUrlPath: ["sites", "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["oneSite/|agency_name"],
            itemInUrlPath: ["sites"],
            urlPathVariableName: ["siteId"],
            noLink: true,
          },
          {
            defaultName: capitalize(t("listsOneEntity.sites", { ns: "campaign" })),
            variableName: ["oneSite"],
            itemInUrlPath: ["sites", "containers", "list"],
            urlPathVariableName: ["siteId"],
          },
          {
            defaultName: t("container"),
            variableName: ["oneSite", "oneContainer"],
            itemInUrlPath: ["sites", "containers", "pixels", "list"],
            urlPathVariableName: ["siteId", "containerId"],
          },
          { defaultName: t("pixel") },
        ],
      };
    }
    case optionsType.pixelContainerSite: {
      return {
        query: pixelContainerSite,
        queryOptions: {
          options: (props) => ({
            fetchPolicy: "network-only",
            variables: {
              idA: props.match.params.siteId || 0,
              idB: props.match.params.containerId || 0,
              idC: props.match.params.pixelId || 0,
            },
            client: lighthouseClient,
          }),
        },
        breadcrumbsPath: [
          { defaultName: capitalize(t("listsMultiple.sites", { ns: "campaign" })), itemInUrlPath: ["sites", "list"] },
          {
            defaultName: t("agency", { context }),
            variableName: ["oneSite/|agency_name"],
            itemInUrlPath: ["sites"],
            urlPathVariableName: ["siteId"],
            noLink: true,
          },
          {
            defaultName: capitalize(t("listsOneEntity.sites", { ns: "campaign" })),
            variableName: ["oneSite"],
            itemInUrlPath: ["sites", "containers", "list"],
            urlPathVariableName: ["siteId"],
          },
          {
            defaultName: t("container"),
            variableName: ["oneSite", "oneContainer"],
            itemInUrlPath: ["sites", "containers", "pixels", "list"],
            urlPathVariableName: ["siteId", "containerId"],
          },
          { defaultName: t("pixel"), variableName: ["onePixel"], itemInUrlPath: [], urlPathVariableName: ["siteId"] },
        ],
      };
    }
    default:
      return false;
  }
};

export const withBreadcrumbs = (Component, optionsTypeName) => {
  const { t } = useTranslation(["common", "campaign"]);
  const context = useI18nContext();
  const options = optionsFactory(optionsTypeName, { t, context });
  if (!options) {
    return compose(withRouter)(Component);
  }
  return compose(
    withRouter,
    graphql(options.query, {
      ...options.queryOptions,
    })
  )((props) => {
    if (props.data.loading) {
      return null;
    }
    return (
      <div>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
          {options.breadcrumbsPath.map((item, index, arr) => {
            const currentVariableName =
              item.variableName &&
              item.urlPathVariableName &&
              item.urlPathVariableName.length &&
              item.variableName[item.urlPathVariableName.length - 1].split("/");
            let breadcrumbTitle = firstLatterToUpperCase(item.defaultName);
            if (currentVariableName && currentVariableName.length && props.data[currentVariableName[0]]) {
              if (currentVariableName.length === 1) {
                breadcrumbTitle = firstLatterToUpperCase(props.data[currentVariableName[0]].name);
              } else {
                const [, titleVareable] = currentVariableName[1].split("|");
                const breadcrumbTitleFromPayload = props.data[currentVariableName[0]][titleVareable];
                if (breadcrumbTitleFromPayload) {
                  breadcrumbTitle = firstLatterToUpperCase(props.data[currentVariableName[0]][titleVareable]);
                }
              }
            }
            if (index < arr.length - 1 && item.noLink !== true) {
              const to = item.itemInUrlPath.reduce((acc, pathItem, i, allArray) => {
                if (!item.variableName || !item.variableName[i]) {
                  acc += `${pathItem}${i < allArray.length - 1 ? "/" : ""}`;
                } else {
                  acc += `${pathItem}/${
                    props.match.params[item.urlPathVariableName[i]] === undefined
                      ? getDeepSerchParams(item.variableName[i].split("/").reverse(), props.data)
                      : props.match.params[item.urlPathVariableName[i]]
                  }/`;
                }
                return acc;
              }, "/");
              return (
                <Link key={index} color="inherit" component={RouterLink} to={to}>
                  {breadcrumbTitle}
                </Link>
              );
            } else {
              return (
                <Typography key={index} color="textPrimary">
                  {breadcrumbTitle}
                </Typography>
              );
            }
          })}
        </Breadcrumbs>
        <Component {...props} />
      </div>
    );
  });
};
