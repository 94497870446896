// modules
import React, { useEffect, useState } from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  snack: {
    marginTop: 50,
  },
}));

function ErrorSnackbar({ error, hide, title, action, variant }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(Boolean(error));
  }, [error]);

  const handleClose = () => setOpen(false);

  return (
    <Snackbar
      onClose={handleClose}
      autoHideDuration={hide}
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      className={classes.snack}
    >
      <Alert action={action} variant="filled" severity={variant}>
        {title}
      </Alert>
    </Snackbar>
  );
}

ErrorSnackbar.propTypes = {
  error: PropTypes.bool,
  hide: PropTypes.number,
  title: PropTypes.string,
  action: PropTypes.element,
  variant: PropTypes.string,
};

ErrorSnackbar.defaultProps = {
  error: false,
  hide: undefined,
  title: "Server Error. Try again later",
  action: null,
  variant: "warning",
};

export default ErrorSnackbar;
