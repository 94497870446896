import React, { useContext, useMemo, useState } from "react";
import { createTheme, getContrastRatio, MuiThemeProvider } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import { useLocalStorageState } from "ahooks";
import { emphasize, alpha } from "@material-ui/core/styles/colorManipulator";
import { observer } from "mobx-react-lite";
import { uiThemeStore } from "../store/uiTheme";
import { useTranslation } from "react-i18next";
import { set } from "mobx";

const INITIAL = {
  primary: blue[400],
};

export const ThemeChangeContext = React.createContext(() => {
  throw new Error("Forgot to wrap component in `ThemeProvider`");
});

export const useSetThemeColors = () => {
  const { setColors } = useContext(ThemeChangeContext);
  return setColors;
};

export const useResetThemeColors = () => {
  const { resetColors } = useContext(ThemeChangeContext);
  return resetColors;
};

const WCAG_RECOMMENDED_MINIMUM_CONTRAST = 3;

export const ThemeProvider = observer(({ children }) => {
  const { i18n, t } = useTranslation(`theme`);
  const primaryColor = t(`colorPrimary`) === `colorPrimary` ? uiThemeStore.colorPrimary : t(`colorPrimary`); // use store value while i18n not populated yet
  const colorTextDark = t(`colorTextDark`) === `colorTextDark` ? uiThemeStore.colorTextDark : t(`colorTextDark`);
  const colorTextLight = t(`colorTextLight`) === `colorTextLight` ? uiThemeStore.colorTextLight : t(`colorTextLight`);
  const setColors = ({ primary }) => {
    set(uiThemeStore, `colorPrimary_${i18n.language}`, primary);
    i18n.addResource(i18n.language, `theme`, `colorPrimary_${i18n.language}`, primary);
  };
  const resetColors = () => setColors({ primary: INITIAL.primary });
  const [canSwitch, setCanSwitch] = useLocalStorageState(`theme-can-switch`, false);
  const [prevColors, setPrevColors] = useState({ primary: INITIAL.primary });

  const getContrastText = (background) =>
    getContrastRatio(background, colorTextLight) >= WCAG_RECOMMENDED_MINIMUM_CONTRAST ? colorTextLight : colorTextDark;

  const theme = useMemo(
    () =>
      createTheme({
        props: {
          MuiTabs: {
            indicatorColor: "primary",
          },
          MuiCheckbox: {
            color: "primary",
          },
          MuiRadio: {
            color: "primary",
          },
        },
        canSwitch,
        typography: {
          useNextVariants: true,
        },
        palette: {
          background: {
            default: grey[50],
            primary: blue[400],
          },
          primary: {
            light: blue[400],
            main: primaryColor,
            dark: blue[400],
            contrastText: getContrastText(primaryColor),
          },
          secondary: {
            light: grey[700],
            main: grey[700],
            dark: grey[700],
          },
          type: "light",
          getContrastText,
        },
        overrides: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "green",
            },
          },
          MuiAppBar: {
            colorPrimary: {
              backgroundColor: "#fff",
            },
          },
          MuiIcon: {
            colorPrimary: {
              color: "#00408F",
            },
          },
          MuiList: {
            padding: {
              paddingTop: "0",
            },
          },
          MuiListItem: {
            gutters: {
              paddingTop: "11px",
              paddingBottom: "11px",
              paddingLeft: "23px",
              paddingRight: "23px",
            },
          },
          MuiListItemIcon: {
            root: {
              color: "#00408F",
            },
          },
          // MuiSvgIcon: {
          //   root: {
          //     color: "inherit",
          //   },
          // },
          MuiTableCell: {
            head: {
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "0.75rem",
              fontWeight: 500,
            },
            root: {
              color: "#000",
              MuiSvgIcon: {
                // colorPrimary: "red",
              },
            },
          },
          MuiPickersDay: {
            root: {
              "&$selected": {
                "&:hover": {
                  backgroundColor: primaryColor,
                },
              },
              "&:focus": {
                "&$selected": {
                  backgroundColor: primaryColor,
                },
              },
            },
          },
          MuiPickersDateRangeDay: {
            rangeIntervalDayHighlight: { backgroundColor: alpha(primaryColor, 0.32) },
          },

          MuiStepLabel: {
            iconContainer: {
              color: "#00408F",
              MuiStepIcon: {
                active: {
                  color: "#00408F",
                  MuiStepIcon: {
                    color: "#fff",
                  },
                },
                root: {
                  color: "#00408F",
                  MuiStepIcon: {
                    color: "#fff",
                  },
                },
              },
            },
          },

          MuiButton: {
            textPrimary: {
              backgroundColor: "#00408F",
              color: "#fff",
              "&:hover": {
                color: "#000",
                backgroundColor: "#e0e0e0",
              },
            },
            containedPrimary: {
              MuiSvgIcon: {
                root: {
                  color: "#fff",
                },
              },
              color: "#fff",
              backgroundColor: "#00408F",
              "&:hover": {
                backgroundColor: emphasize(primaryColor, 0.08),
              },
            },
          },
        },
      }),
    [primaryColor, canSwitch]
  );

  const handleSetColors = ({ primary }) => {
    setPrevColors({ primary: theme.palette.primary.main });
    setColors({ primary });
  };

  const handleToggleInitialColors = () => {
    handleSetColors({
      primary: theme.palette.primary.main === INITIAL.primary ? prevColors.primary : INITIAL.primary,
    });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeChangeContext.Provider
        value={{
          setColors: handleSetColors,
          resetColors,
          toggleColors: handleToggleInitialColors,
          canSwitch,
          setCanSwitch,
          isInitialColors: theme.palette.primary.main === INITIAL.primary,
        }}
      >
        {children}
      </ThemeChangeContext.Provider>
    </MuiThemeProvider>
  );
});
