import React from "react";
import { Typography, Breadcrumbs } from "@material-ui/core";
import fetchOneSite from "../../graphql/sites/fetchOneSite.graphql";
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useLighthouseQuery } from "../../utils/lighthouseHooks";

const SITE_NAME_PATTERN = "#siteName#";

const SiteLayout = ({ children, componentProps }) => {
  const params = useParams();

  const { data, loading } = useLighthouseQuery(fetchOneSite, {
    variables: {
      id: params.siteId,
    },
  });

  const agencyName = loading ? "" : data?.site.agency_name;
  const siteName = loading ? "Site #" + params.siteId : data?.site.name;

  const pageTitle = componentProps.hasOwnProperty("pageTitle") ? componentProps.pageTitle : SITE_NAME_PATTERN;

  return (
    <div>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
        <Link color="inherit" component={RouterLink} to="/sites/list">
          Sites
        </Link>
        {!loading && <Typography color="textPrimary">{agencyName}</Typography>}
        <Typography color="textPrimary">{pageTitle.replace(SITE_NAME_PATTERN, siteName)}</Typography>
      </Breadcrumbs>
      {children}
    </div>
  );
};

export default SiteLayout;
