// modules
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PT from "prop-types";

// components
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";

// styles
const useStyles = makeStyles(() => ({
  redBtn: {
    color: "red",
  },
  root: {
    marginTop: 10,
  },
}));

// methods
const watchLeavePage = (e) => {
  e.preventDefault();
  e.returnValue = "";
};

const FormFooter = (props) => {
  const { backUrl, disabled, hide, loading, onClose, onSave } = props;

  const css = useStyles();
  const { t } = useTranslation("common");
  const history = useHistory();

  // state
  const [open, setOpen] = useState(false);
  const [isDirty, setDirty] = useState(false);

  // effects
  useEffect(() => {
    if (isDirty !== props.isDirty) {
      setDirty(props.isDirty);
    }
  }, [props.isDirty]);

  useEffect(() => {
    if (!isDirty || hide) {
      return;
    }

    const blockHistoryChange = history.block(({ pathname: nextPathname }) => {
      if (history.location.pathname === nextPathname) {
        return true;
      }

      return window.confirm("Leave the page? Changes you made may not be saved.");
    });

    window.addEventListener("beforeunload", watchLeavePage);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("beforeunload", watchLeavePage);
      blockHistoryChange();
    };
  }, [isDirty]);

  // methods
  const unblockHistory = () => {
    const removeBlock = history.block();
    removeBlock();
  };

  const handleClose = () => {
    unblockHistory();

    if (onClose) {
      onClose();
    }

    // eslint-disable-next-line prettier/prettier
    else if (backUrl) {
      history.replace(backUrl);
    }
  };

  const handleSave = (e) => {
    unblockHistory();
    setDirty(false);
    onSave(e);
  };

  if (hide) {
    return null;
  }

  return (
    <div className={css.root}>
      <Grid container justify="flex-end" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            disabled={disabled || loading || !props.isDirty}
            variant="contained"
            onClick={handleSave}
          >
            {loading ? t("saving") : t("save")}
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={() => (isDirty ? setOpen(true) : handleClose())} color="primary">
            {t("close")}
          </Button>
        </Grid>
      </Grid>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">{t("Discard all changes?")}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("You have changes that are not saved. Do you really want to discard these changes?")}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button className={css.redBtn} onClick={handleClose}>
            {t("discard")}
          </Button>

          <Button autoFocus color="primary" onClick={() => setOpen(false)}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FormFooter.propTypes = {
  onSave: PT.func.isRequired,
  loading: PT.bool,
  disabled: PT.bool,
  backUrl: PT.string,
  onClose: PT.func,
  isDirty: PT.bool,
  hide: PT.bool,
};

FormFooter.defaultProps = {
  loading: false,
  disabled: false,
  backUrl: "",
  onClose: null,
  isDirty: false,
  hide: false,
};

export default FormFooter;
