// eslint-disable-next-line no-undef
const CONFIG_FILENAME = CONFIG_JSON_FILENAME; // webpack defined, 'config.json' by default
const CONFIG_URL = `/${CONFIG_FILENAME}`;

class ConfigService {
  IS_RETAIL_MODE = false; // DSP mode by default\false, RETAIL mode if true
  API_DOMAIN = process.env.REACT_APP_ENDPOINT;
  API_LIGHTHOUSE_ENDPOINT = process.env.REACT_APP_LIGHTHOUSE_ENDPOINT;
  API_VIQEO_STUDIO_SCRIPT = process.env.REACT_APP_VIQEO_STUDIO_SCRIPT;
  SYNC_URL = process.env.SYNC_URL;
  BID_URL = process.env.BID_URL;
  NO_AVATAR = "";

  /**
   * Fetch and replace config values
   * @return {Promise<Response | void>}
   */
  init = () =>
    fetch(CONFIG_URL)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(`Response to ${CONFIG_URL} not ok`);
        }
      })
      .then((response) => {
        if (typeof response === "object" && response !== null) {
          Object.entries(response).forEach(([key, value]) => {
            if (![`init`, `get`].includes(key)) {
              this[key] = value;
            }
          });
        }
      });
}

export const configService = new ConfigService(); // singleton

export default configService;
