import { useQuery } from "@apollo/react-hooks";
import useGraphqlResponseParser from "../components/NotificationCenter/useGraphqlResponseParser";

function useQueryWithNotification(mutation, opts) {
  const {
    entity,
    operationName = "get",
    shouldNotifyOnError = true,
    shouldNotifyOnSuccess = false,
    ...options
  } = opts || {};

  const response = useQuery(mutation, options);

  useGraphqlResponseParser({
    response,
    entity,
    operationName,
    shouldNotifyOnError,
    shouldNotifyOnSuccess,
  });

  return response;
}

export default useQueryWithNotification;
