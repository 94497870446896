import configService from "../configService";
import { useMemo } from "react";

export const useRoutesNames = () => {
  const names = useMemo(
    () => ({
      paths: {
        resellerBase: `/admin/${configService.IS_RETAIL_MODE ? "retailer" : "reseller"}`,
        agenciesBase: `/${configService.IS_RETAIL_MODE ? "sellers" : "agencies"}`,
        advertisersBase: `/${configService.IS_RETAIL_MODE ? "brands" : "advertisers"}`,
      },
      queryParamsMap: {
        resellerId: configService.IS_RETAIL_MODE ? "retailerId" : "resellerId",
      },
    }),
    [configService.IS_RETAIL_MODE]
  );
  return names;
};

export default useRoutesNames;
