import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import LanguageIcon from "@material-ui/icons/Language";
import { useTranslation } from "react-i18next";

const ChangeLanguageButton = ({ className }) => {
  const { t, i18n } = useTranslation([`common`]);
  const handleSwitchLanguage = () => i18n?.changeLanguage?.(i18n.language === `ru` ? `en` : `ru`);

  return (
    <Button
      className={className}
      onClick={handleSwitchLanguage}
      color="secondary"
      variant={"outlined"}
      startIcon={<LanguageIcon />}
    >
      {t("Change language")}
    </Button>
  );
};

ChangeLanguageButton.propTypes = {
  className: PropTypes.string,
};

export default ChangeLanguageButton;
