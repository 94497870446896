// modules
import { useMemo, useEffect } from "react";

// hooks
import { useQuery } from "@apollo/react-hooks";

// graphql
import currentUser from "../graphql/auth/currentUser.graphql";

// utils
import { lighthouseClient } from "../apollo/LighthouseClient";

// context
import userContext from "../store/userContext";

export const useAuth = () => {
  // graphql
  const { data, loading, error, refetch } = useQuery(currentUser, { client: lighthouseClient });

  // constants
  const user = data?.user;

  const isLoggedIn = Boolean(loading === false && error === undefined && user !== null);
  const isAdmin = isLoggedIn ? user.is_admin === 1 : false;

  const agencyId = userContext.getAgencyId();

  const userAllPermissions = user?.all_permissions ?? [];
  const userLimitCampaignTypes = user?.limit_campaign_types ?? []; // [] = нет ограничений
  const userLimitCampaignStrategies = user?.limit_campaign_strategies ?? null; // null = нет ограничнеий

  // memoized constants
  const allPermissionsMap = useMemo(() => {
    return userAllPermissions.reduce((obj, permission) => ({ ...obj, [permission.name]: permission }), {});
  }, [userAllPermissions]);

  /* null = нет ограничений */
  const limitCampaignTypesData = useMemo(() => {
    if (!userLimitCampaignTypes.length) {
      return null;
    }

    return userLimitCampaignTypes.reduce((obj, type) => ({ ...obj, [type]: type }), {});
  }, [userLimitCampaignTypes]);

  /* null = нет ограничений */
  const limitCampaignStrategiesData = useMemo(() => {
    return userLimitCampaignStrategies?.reduce((obj, type) => ({ ...obj, [type]: type }), {}) ?? null;
  }, [userLimitCampaignStrategies]);

  // methods
  const handleCan = (permissionName) => {
    if (!isLoggedIn) {
      return false;
    }

    if (isAdmin) {
      return true;
    }

    return Boolean(allPermissionsMap[permissionName]);
  };

  // effects
  useEffect(() => {
    if (user && !handleCan("access to switch agency")) {
      userContext.setAgency({
        id: user?.agency_id,
        name: user?.agency_name,
      });
    }
    if (user) {
      userContext.setUser(user);
    }
  }, [user, agencyId]);

  // result
  return useMemo(() => {
    return {
      loading,

      user,

      isAdmin,
      isLogged: isLoggedIn,

      limitCampaignTypesData,
      limitCampaignStrategiesData,

      refetch,

      hasAccessToAdvCost: handleCan("access to adv cost"),
      hasAccessToAdvertisers: handleCan("access to advertisers"),
      hasAccessToAudienceReports: handleCan("audience reports access"),
      hasAccessToCompoundInvoices: handleCan("compound invoices"),
      hasAccessToCpaSmartpixel: handleCan("access to cpa smartpixel"),
      hasAccessToDcoFeed: handleCan("dco_feed_creative access"),
      hasAccessToDeveloperField: handleCan("access to developer field"),
      hasAccessToEditContracts: handleCan("contracts edit"),
      hasAccessToInvoices: handleCan("access to invoices"),
      hasAccessToLogs: handleCan("access to logs"),
      hasAccessToManageAgencies: handleCan("access to manage agencies") || handleCan("access to edit agency"),
      hasAccessToManageSegments: handleCan("access to manage segments"),
      hasAccessToModeration: handleCan("moderate creatives"),
      hasAccessToPixels: handleCan("access to smartpixel"),
      hasAccessToReadAdvertisers: handleCan("view advertisers"),
      hasAccessToReport: handleCan("report access"),
      hasAccessToSelfServiceInvoices: handleCan("access to self-service invoices"),
      hasAccessToSetAdvertiserCommission: handleCan("set advertiser commission"),
      hasAccessToSetBeelineDataCommission: handleCan("set beeline data commission"),
      hasAccessToSupportTag: handleCan("access to support_tag"),
      hasAccessToSwitchAgency: handleCan("access to switch agency"),
      hasAccessToUsers: handleCan("admin users"),
      hasAccessToViewBeelineDataCommission: handleCan("view beeline data commission"),
      hasAccessToViqeoBrandLift: handleCan("viqeo brand lift access"),

      hasLimitAdvertisersPermission: handleCan("limit advertisers"),
      hasResellerPermission: handleCan("reseller"),
    };
  }, [user]);
};
