import {
  Grid,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cloneDeep from "lodash/cloneDeep";
import { useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import lodash from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import updateProfileMutation from "../../graphql/users/updateProfileMutation.graphql";
import currentUser from "../../graphql/auth/currentUser.graphql";
import { useLighthouseMutation } from "../../utils/lighthouseHooks";
import { LANGUAGE_ENUM } from "../../i18n/consts";
import Boundary from "../../components/Boundary";
import { lighthouseClient } from "../../apollo/LighthouseClient";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import ProfileForm from "./ProfileForm";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  view: {
    width: "90%",
    margin: 30,
  },
  viewButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
  cell: {
    borderBottom: `none`,
  },
  titleCell: {
    width: `1%`,
    whiteSpace: `nowrap`,
    paddingRight: theme.spacing(2),
  },
  menuItem: {
    display: "flex",
  },
}));

export const PREVIEW = "preview";
export const EDIT = "edit";

const PreviewRow = ({ title, value, loading }) => {
  const css = useStyles();
  return (
    <TableRow key={title}>
      <TableCell className={cn(css.cell, css.titleCell)}>
        <Typography variant="subtitle1">{`${title} `}</Typography>
      </TableCell>
      <TableCell className={css.cell}>
        {loading ? (
          <Skeleton />
        ) : (
          <Typography variant="subtitle1">
            <strong> {value}</strong>
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

const ProfileEditPage = () => {
  const [step, setStep] = useState(PREVIEW);
  const history = useHistory();
  const css = useStyles();
  const { t } = useTranslation("common");
  const { pathname } = history.location;
  const { data, loading, error } = useQuery(currentUser, { fetchPolicy: "network-only", client: lighthouseClient });
  const [saveProfile, saveResponse] = useLighthouseMutation(updateProfileMutation);
  const profile = data?.user;

  const handleSubmit = (form) => {
    const profile = cloneDeep(form);

    saveProfile({ variables: { profile } }).then(() => {
      history.push(`/profile/${PREVIEW}`);
    });
  };

  useEffect(() => {
    if (!pathname.includes(step)) setStep(step === EDIT ? PREVIEW : EDIT);
  }, [step, pathname]);

  const lang = useMemo(() => lodash.find(LANGUAGE_ENUM, { value: profile?.lang }), [profile]);

  const previewItems = useMemo(
    () => [
      { title: t("email"), value: ` ${profile?.email}` },
      { title: t("name"), value: ` ${profile?.name}` },
      { title: t("language"), value: ` ${lang?.label}` },
      { title: t("timeZone"), value: ` ${profile?.tz}` },
      { title: t("token"), value: ` ${profile?.token || "-"}` },
    ],
    [profile]
  );

  return (
    <Boundary data={profile} loading={loading} error={error}>
      <div data-testid="ProfileEditPage">
        {step === PREVIEW ? (
          <Card data-testid="ViewProfileCard" className={css.view}>
            <CardHeader title={t("myProfile")} />
            <Divider />
            <CardContent>
              <Grid xs={8} container className={css.tableContainer}>
                <Table padding="none" size="small">
                  <TableBody>
                    {previewItems.map((item) => (
                      <PreviewRow key={item.title} loading={loading} title={item.title} value={item.value} />
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12} className={css.viewButtonContainer}>
                <Button data-testid="editProfile" component={Link} to={`/profile/${EDIT}`} color="primary">
                  {t("edit")}
                </Button>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <div data-testid="EditProfileForm">
            <Typography variant="h4" gutterBottom>
              {`${t("edit")} ${t("myProfile").toLowerCase()}`}
            </Typography>
            {profile && (
              <ProfileForm
                loading={loading || saveResponse.loading}
                error={error}
                onSubmit={handleSubmit}
                user={profile}
              />
            )}
          </div>
        )}
      </div>
    </Boundary>
  );
};

export default ProfileEditPage;
