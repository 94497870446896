// modules
import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import PrivateRoute from "./PrivateRoute";
import AdvertiserInnerLayoutWrapper from "../containers/AdData/AdvertiserInnerLayoutWrapper";
import AdvertiserLayoutWrapper from "../containers/AdData/AdvertiserLayoutWrapper";
import LayoutWrapper from "../containers/Layout/LayoutWrapper";
import SiteLayoutWrapper from "../containers/SiteData/SitesLayoutWrapper";

// hooks
import useRoutesNames from "./useRoutesNames";
import useI18nContext from "../utils/hooks/useI18nContext";

// static
import configService from "../configService";
import { EDIT, PREVIEW } from "../containers/User/ProfileEditPage";
import { withBreadcrumbs, optionsType } from "../components/breadcrumbs/breadcrumbs";

/* pages */
const Dashboard = lazy(() => import("../containers/Dashboard"));
const NotFound = lazy(() => import("../containers/Services/NotFound"));
const AuthPage = lazy(() => import("../containers/Auth/AuthPage"));
const RegisterPage = lazy(() => import("../containers/Auth/RegisterPage"));
const RedirectIfAuthRoute = lazy(() => import("./RedirectIfAuthRoute"));
const AccessDenied = lazy(() => import("../containers/Services/AccessDenied"));
const DebugPage = lazy(() => import("../containers/DebugPage/DebugPage"));
const CopySegmentsCost = lazy(() => import("../containers/CopySegmentsCost"));

/* reports */
const ReportsPage = lazy(() => import("../containers/Reporting/ReportsPage"));

/* logs */
const LogsPage = lazy(() => import("../containers/Logs/LogsPage"));

/* moderation */
const Moderation = lazy(() => import("../containers/Moderation"));

/* users */
const UserListPage = lazy(() => import("../containers/User/UserListPage"));
const UserEditPage = lazy(() => import("../containers/User/UserEditPage"));
const UserCreatePage = lazy(() => import("../containers/User/UserCreatePage"));

/* profile */
const ProfileEditPage = lazy(() => import("../containers/User/ProfileEditPage"));

/* SP segments */
const LoaderPage = lazy(() => import("../containers/SPSegment/LoaderPage"));

/* roles */
const RoleListPage = lazy(() => import("../containers/Role/RoleListPage"));
const RoleCreatePage = lazy(() => import("../containers/Role/RoleCreatePage"));
const RoleEditPage = lazy(() => import("../containers/Role/RoleEditPage"));

/* sites */
const SitesListPage = lazy(() => import("../containers/SiteData/Sites/SitesListPage"));
const SitesEditPage = lazy(() => import("../containers/SiteData/Sites/SitesEditPage"));
const SitesCreatePage = lazy(() => import("../containers/SiteData/Sites/SitesCreatePage"));

/* plates */
const DcoFeedTemplateListPage = lazy(() => import("../containers/DcoFeedTemplates/DcoFeedTemplateListPage"));
const DcoFeedTemplateCreatePage = lazy(() => import("../containers/DcoFeedTemplates/DcoFeedTemplateCreatePage"));
const DcoFeedTemplateEditPage = lazy(() => import("../containers/DcoFeedTemplates/DcoFeedTemplateEditPage"));

/* containers */
const ContainersListPage = lazy(() => import("../containers/SiteData/Containers/ContainersListPage"));
const ContainersEditPage = lazy(() => import("../containers/SiteData/Containers/ContainersEditPage"));
const ContainersCreatePage = lazy(() => import("../containers/SiteData/Containers/ContainersCreatePage"));

/* pixels */
const PixelsListPage = lazy(() => import("../containers/SiteData/Pixels/PixelsListPage"));
const PixelsEditPage = lazy(() => import("../containers/SiteData/Pixels/PixelsEditPage"));
const PixelsCreatePage = lazy(() => import("../containers/SiteData/Pixels/PixelsCreatePage"));

/* advertisers */
const AdvertiserCreatePage = lazy(() => import("../containers/AdData/Advertisers/AdvertiserCreatePage"));
const AdvertiserEditPage = lazy(() => import("../containers/AdData/Advertisers/AdvertiserEditPage"));
const AdvertisersListPage = lazy(() => import("../containers/AdData/Advertisers/AdvertisersListPage"));

/* campaings */
const CampaignCreatePage = lazy(() => import("../containers/AdData/Campaigns/CampaignCreatePage"));
const CampaignCopyPage = lazy(() => import("../containers/AdData/Campaigns/CampaignCopyPage"));
const CampaignEditPage = lazy(() => import("../containers/AdData/Campaigns/CampaignEditPage"));
const CampaignsListPage = lazy(() => import("../containers/AdData/Campaigns/CampaignsListPage"));

/* creatives */
const CreativeList = lazy(() => import("../containers/AdData/Creatives/List"));
const CreativeCreate = lazy(() => import("../containers/AdData/Creatives/Create"));
const CreativeEdit = lazy(() => import("../containers/AdData/Creatives/Edit"));
const CreativeCopy = lazy(() => import("../containers/AdData/Creatives/Copy"));
const BulkUpload = lazy(() => import("../containers/AdData/Creatives/BulkUpload"));

/* domains */
const DomainsListListPage = lazy(() => import("../containers/AdData/DomainsList/DomainsListListPage"));
const DomainsListEditPage = lazy(() => import("../containers/AdData/DomainsList/DomainsListEditPage"));
const DomainsListCreatePage = lazy(() => import("../containers/AdData/DomainsList/DomainsListCreatePage"));

/* publishers */
const PublishersListListPage = lazy(() => import("../containers/AdData/PublishersList/PublishersListListPage"));
const PublishersListCreatePage = lazy(() => import("../containers/AdData/PublishersList/PublishersListCreatePage"));
const PublishersListEditPage = lazy(() => import("../containers/AdData/PublishersList/PublishersListEditPage"));

/* productFeed */
const ProductFeedListPage = lazy(() => import("../containers/AdData/ProductFeedList/ProductFeedListPage"));
const ProductFeedCreatePage = lazy(() => import("../containers/AdData/ProductFeedList/ProductFeedCreatePage"));
const ProductFeedEditPage = lazy(() => import("../containers/AdData/ProductFeedList/ProductFeedEditPage"));

/* apps */
const AppsListListPage = lazy(() => import("../containers/AdData/AppsList/AppsListListPage"));
const AppsListCreatePage = lazy(() => import("../containers/AdData/AppsList/AppsListCreatePage"));
const AppsListEditPage = lazy(() => import("../containers/AdData/AppsList/AppsListEditPage"));

/* sites */
const SitesListListPage = lazy(() => import("../containers/AdData/SitesList/SitesListListPage"));
const SitesListCreatePage = lazy(() => import("../containers/AdData/SitesList/SitesListCreatePage"));
const SitesListEditPage = lazy(() => import("../containers/AdData/SitesList/SitesListEditPage"));

/* ips */
const IPsListListPage = lazy(() => import("../containers/AdData/IPsList/IPsListListPage"));
const IPsListCreatePage = lazy(() => import("../containers/AdData/IPsList/IPsListCreatePage"));
const IPsListEditPage = lazy(() => import("../containers/AdData/IPsList/IPsListEditPage"));

/* campaign groups */
const CampaignGroupListPage = lazy(() => import("../containers/AdData/CampaignGroup/CampaignGroupListPage"));
const CampaignGroupCreatePage = lazy(() => import("../containers/AdData/CampaignGroup/CampaignGroupCreatePage"));
const CampaignGroupEditPage = lazy(() => import("../containers/AdData/CampaignGroup/CampaignGroupEditPage"));

/* segments */
const SegmentsPage = lazy(() => import("../containers/AgencySegments/Segments"));

/* refs */
const RefTablesListPage = lazy(() => import("../containers/Admin/RefTables/RefTablesListPage"));
const RefTableCreatePage = lazy(() => import("../containers/Admin/RefTables/RefTableCreatePage"));
const RefTableEditPage = lazy(() => import("../containers/Admin/RefTables/RefTableEditPage"));

/* datasets */
const DatasetsListPage = lazy(() => import("../containers/Admin/Datasets/DatasetsListPage"));
const DatasetCreatePage = lazy(() => import("../containers/Admin/Datasets/DatasetCreatePage"));
const DatasetEditPage = lazy(() => import("../containers/Admin/Datasets/DatasetEditPage"));

/* agencies */
const AgenciesEditPage = lazy(() => import("../containers/Admin/Agencies/AgenciesEditPage"));
const AgenciesCreatePage = lazy(() => import("../containers/Admin/Agencies/AgenciesCreatePage"));
const AgenciesListPage = lazy(() => import("../containers/Admin/Agencies/AgenciesListPage"));

/* dictionaries */
const SSPCreate = lazy(() => import("../containers/SSP/SSPCreate"));
const SSPEdit = lazy(() => import("../containers/SSP/SSPEdit"));
const SSPList = lazy(() => import("../containers/SSP/SSPList"));

/* lists */
const ListPage = lazy(() => import("../containers/Lists/ListPage"));
const ListEditPage = lazy(() => import("../containers/Lists/ListEditPage"));
const ListCreatePage = lazy(() => import("../containers/Lists/ListCreatePage"));

/* whitelabel */
const WhitelabelListPage = lazy(() => import("../containers/Admin/Whitelabel/WhitelabelListPage"));
const WhitelabelCreatePage = lazy(() => import("../containers/Admin/Whitelabel/WhitelabelCreatePage"));
const WhitelabelEditPage = lazy(() => import("../containers/Admin/Whitelabel/WhitelabelEditPage"));

/* audience reports */
const AudienceReportsPage = lazy(() => import("../containers/AudienceReports/AudienceReportsPage"));

/* resellers */
const ResellerListPage = lazy(() => import("../containers/Admin/Reseller/ResellerListPage"));
const ResellerCreatePage = lazy(() => import("../containers/Admin/Reseller/ResellerCreatePage"));
const ResellerEditPage = lazy(() => import("../containers/Admin/Reseller/ResellerEditPage"));

/* ord */
const OrdPage = lazy(() => import("../containers/Ord/OrdPage"));
const ContractCreatePage = lazy(() => import("../containers/Ord/ContractCreatePage"));
const ContractEditPage = lazy(() => import("../containers/Ord/ContractEditPage"));
const InvoiceCreatePage = lazy(() => import("../containers/Ord/InvoiceCreatePage"));
const InvoiceEditPage = lazy(() => import("../containers/Ord/InvoiceEditPage"));
const InvoiceReportPage = lazy(() => import("../containers/Ord/InvoiceReportPage"));

// methods
const showLists = (auth) => {
  const IS_RETAIL_MODE = configService.IS_RETAIL_MODE;

  if (IS_RETAIL_MODE) {
    return Boolean(auth.limitCampaignTypesData === null || auth.limitCampaignTypesData.banner_ad);
  }

  return auth.hasAccessToAdvertisers;
};

const showCreatives = (auth) => {
  const IS_RETAIL_MODE = configService.IS_RETAIL_MODE;

  if (IS_RETAIL_MODE) {
    return Boolean(
      auth.limitCampaignTypesData === null ||
        auth.limitCampaignTypesData.banner_ad ||
        auth.limitCampaignTypesData.banner_ad_onsite
    );
  }

  return true;
};

const Routes = () => {
  const { t } = useTranslation(["common", "campaignGroups", "creatives", "containers"]);
  const { paths } = useRoutesNames();
  const context = useI18nContext();
  const IS_RETAIL_MODE = configService.IS_RETAIL_MODE;

  return (
    <Switch>
      <PrivateRoute path="/" exact isGranted={(auth) => auth.isLogged} component={LayoutWrapper(Dashboard)} />

      <PrivateRoute path={`/debug`} exact component={LayoutWrapper(DebugPage)} />
      <PrivateRoute
        path={`/profile/${PREVIEW}`}
        exact
        isGranted={(auth) => auth.isLogged}
        component={LayoutWrapper(ProfileEditPage)}
        title={`${t("profile")}`}
      />
      <PrivateRoute
        path={`/profile/${EDIT}`}
        exact
        isGranted={(auth) => auth.isLogged}
        component={LayoutWrapper(ProfileEditPage)}
        title={`${t(`edit`)} — ${t("profile")}`}
      />

      <PrivateRoute
        path="/admin/users"
        exact
        isGranted={(auth) => auth.isAdmin || auth.hasAccessToUsers}
        component={LayoutWrapper(UserListPage)}
        title={`${t("users")}`}
      />
      <PrivateRoute
        path="/admin/users/create"
        exact
        isGranted={(auth) => auth.isAdmin || auth.hasAccessToUsers}
        component={LayoutWrapper(UserCreatePage)}
        title={`${t(`createNew`)} — ${t("users")}`}
      />
      <PrivateRoute
        path="/admin/users/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin || auth.hasAccessToUsers}
        component={LayoutWrapper(UserEditPage)}
        title={`${t(`edit`)} — ${t("users")}`}
      />

      <PrivateRoute
        path="/admin/roles"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(RoleListPage)}
        title={`${t("roles")}`}
      />
      <PrivateRoute
        path="/admin/roles/create"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(RoleCreatePage)}
        title={`${t(`createNew_she`)} — ${t("roles")}`}
      />
      <PrivateRoute
        path="/admin/roles/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(RoleEditPage)}
        title={`${t(`edit`)} — ${t("roles")}`}
      />
      <PrivateRoute
        path="/admin/supply-side-platforms"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(SSPList)}
        title={`${t("ssp")}`}
      />
      <PrivateRoute
        path="/admin/supply-side-platforms/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(SSPEdit)}
        title={`${t(`edit`)} — ${t("ssp")}`}
      />
      <PrivateRoute
        path="/admin/supply-side-platforms/create"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(SSPCreate)}
        title={`${t(`createNew`)} — ${t("ssp")}`}
      />

      <PrivateRoute
        path="/segments"
        exact
        isGranted={(auth) => auth.hasAccessToManageSegments}
        component={LayoutWrapper(SegmentsPage)}
        title={`${t("segments")}`}
      />

      <PrivateRoute
        path="/admin/segments"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(SegmentsPage)}
        title={`${t("segments")}`}
      />

      <PrivateRoute
        path="/global_lists"
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers}
        component={LayoutWrapper(ListPage)}
        title={`${t("globalLists")}`}
      />
      <PrivateRoute
        path="/global_lists/:list/:id/edit"
        exact
        isGranted={(auth) => showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(ListEditPage)}
        title={`${t(`edit`)} — ${t("globalLists")}`}
      />
      <PrivateRoute
        path="/global_lists/:list/create"
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers}
        component={LayoutWrapper(ListCreatePage)}
        title={`${t(`createNew`)} — ${t("globalLists")}`}
      />

      <PrivateRoute
        path={"/logs"}
        exact
        isGranted={(auth) => auth.hasAccessToLogs}
        component={LayoutWrapper(LogsPage)}
        title={`${t("logs")}`}
      />

      <PrivateRoute
        path="/admin/spsegments/loader"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(LoaderPage)}
        title={`${t("spsegments")}`}
      />

      <PrivateRoute
        path="/admin/reftables"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(RefTablesListPage)}
        title={`${t("refTables")}`}
      />
      <PrivateRoute
        path="/admin/reftables/create"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(RefTableCreatePage)}
        title={`${t(`createNew_she`)} — ${t("refTables")}`}
      />
      <PrivateRoute
        path="/admin/reftables/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(RefTableEditPage)}
        title={`${t(`edit`)} — ${t("refTables")}`}
      />

      <PrivateRoute
        path="/admin/datasets"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(DatasetsListPage)}
        title={`${t("datasets")}`}
      />
      <PrivateRoute
        path="/admin/datasets/create"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(DatasetCreatePage)}
        title={`${t(`createNew_she`)} — ${t("datasets")}`}
      />
      <PrivateRoute
        path="/admin/datasets/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(DatasetEditPage)}
        title={`${t(`edit`)} — ${t("datasets")}`}
      />

      <PrivateRoute
        path="/admin/dco-feed-templates"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(DcoFeedTemplateListPage)}
        title={`${t("templates")}`}
      />
      <PrivateRoute
        path="/admin/dco-feed-templates/create"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(DcoFeedTemplateCreatePage)}
        title={`${t(`createNew`)} — ${t("templates")}`}
      />
      <PrivateRoute
        path="/admin/dco-feed-templates/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(DcoFeedTemplateEditPage)}
        title={`${t(`edit`)} — ${t("templates")}`}
      />
      <PrivateRoute
        path="/admin/wl"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(WhitelabelListPage)}
        title={`${t("whitelabel")}`}
      />
      <PrivateRoute
        path="/admin/wl/create"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(WhitelabelCreatePage)}
        title={`${t(`createNew`)} — ${t("whitelabel")}`}
      />
      <PrivateRoute
        path="/admin/wl/:id/edit"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(WhitelabelEditPage)}
        title={`${t(`edit`)} — ${t("whitelabel")}`}
      />
      <PrivateRoute
        path={`${paths.resellerBase}`}
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(ResellerListPage)}
        title={`${t("resellers")}`}
      />
      <PrivateRoute
        path={`${paths.resellerBase}/create`}
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(ResellerCreatePage)}
        title={`${t(`createNew`)} — ${t("resellers")}`}
      />
      <PrivateRoute
        path={`${paths.resellerBase}/:id/edit`}
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(ResellerEditPage)}
        title={`${t(`edit`)} — ${t("resellers")}`}
      />
      <PrivateRoute
        path={`${paths.agenciesBase}`}
        exact
        isGranted={(auth) => auth.isAdmin || auth.hasAccessToManageAgencies}
        component={LayoutWrapper(AgenciesListPage)}
        title={`${t("agencies", { ns: "common", context })}`}
      />
      <PrivateRoute
        path={`${paths.agenciesBase}/create`}
        exact
        isGranted={(auth) => auth.isAdmin || auth.hasAccessToManageAgencies}
        component={LayoutWrapper(AgenciesCreatePage)}
        title={`${t(`createNew_it`)} — ${t("agencies", { ns: "common", context })}`}
      />
      <PrivateRoute
        path={`${paths.agenciesBase}/:id/edit`}
        exact
        isGranted={(auth) => auth.isAdmin || auth.hasAccessToManageAgencies}
        component={LayoutWrapper(AgenciesEditPage)}
        title={`${t(`edit`)} — ${t("agencies", { ns: "common", context })}`}
      />
      <PrivateRoute
        path="/admin/copy-segment-costs"
        exact
        isGranted={(auth) => auth.isAdmin}
        component={LayoutWrapper(CopySegmentsCost)}
        title={`Copy segment costs`}
      />

      <PrivateRoute
        path="/moderation"
        exact
        isGranted={(auth) => auth.hasAccessToModeration}
        component={LayoutWrapper(Moderation)}
        title={`${t("moderation")}`}
      />

      <PrivateRoute
        path="/reporting"
        exact
        isGranted={(auth) => auth.hasAccessToReport}
        component={LayoutWrapper(ReportsPage)}
        title={`${t("reports")}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/list`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(AdvertisersListPage)}
        title={`${t("advertisers", { context })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/create`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers}
        component={LayoutWrapper(AdvertiserCreatePage)}
        title={`${t(`createNew`)} — ${t("advertisers", { context })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:id/edit`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(AdvertiserEditPage)}
        title={`${t(`edit`)} — ${t("advertisers", { context })}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/:groupId/campaigns`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(withBreadcrumbs(CampaignsListPage, optionsType.compaignsGroup))}
        title={`${t("campaigns")}`}
      />

      {/* isCampaignTypeRetail === true */}
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/:groupId/campaigns/create_product`}
        exact
        isGranted={(auth) =>
          auth.hasAccessToAdvertisers &&
          (auth?.limitCampaignTypesData === null || auth?.limitCampaignTypesData?.product_ad)
        }
        component={LayoutWrapper(withBreadcrumbs(CampaignCreatePage, optionsType.compaingCreate))}
        title={`${t(`createNew_she`)} — ${t("campaign")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/:groupId/campaigns/create_banner_onsite`}
        exact
        isGranted={(auth) =>
          auth.hasAccessToAdvertisers &&
          (auth?.limitCampaignTypesData === null || auth?.limitCampaignTypesData?.banner_ad_onsite)
        }
        component={LayoutWrapper(withBreadcrumbs(CampaignCreatePage, optionsType.compaingCreate))}
        title={`${t(`createNew_she`)} — ${t("campaign")}`}
      />

      {/* isCampaignTypeRetail === false */}
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/:groupId/campaigns/create`}
        exact
        isGranted={(auth) =>
          auth.hasAccessToAdvertisers &&
          (auth?.limitCampaignTypesData === null || auth?.limitCampaignTypesData?.banner_ad)
        }
        component={LayoutWrapper(withBreadcrumbs(CampaignCreatePage, optionsType.compaingCreate))}
        title={`${t(`createNew_she`)} — ${t("campaign")}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns/:id/edit`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(withBreadcrumbs(CampaignEditPage, optionsType.compaings))}
        title={`${t(`edit`)} — ${t("campaign")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns/:id/copy`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(withBreadcrumbs(CampaignCopyPage, optionsType.compaingCopy))}
        title={`${t(`copy`)} — ${t("campaign")}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/bulk_edit/:id/edit`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(AdvertiserLayoutWrapper(CampaignEditPage))}
        title={`${t("bulkEdit")} — ${t("campaign")}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/domains_list/list`}
        exact
        isGranted={(auth) => showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(AdvertiserLayoutWrapper(DomainsListListPage))}
        title={`${t("domainsListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/domains_list/create`}
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(DomainsListCreatePage, [
            "#advertiserName#",
            "domains_list/list",
            `${t(`createNew`)} ${t("domainsListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`createNew`)} — ${t("domainsListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/domains_list/:id/edit`}
        exact
        isGranted={(auth) => showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(DomainsListEditPage, [
            "#advertiserName#",
            "domains_list/list",
            `${t(`edit`)} ${t("domainsListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`edit`)} — ${t("domainsListTitle", { list: t("list") })}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/publishers_list/list`}
        exact
        isGranted={(auth) =>
          showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers) && !IS_RETAIL_MODE
        }
        component={LayoutWrapper(AdvertiserLayoutWrapper(PublishersListListPage))}
        title={`${t("publishersListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/publishers_list/create`}
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers && !IS_RETAIL_MODE}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(PublishersListCreatePage, [
            "#advertiserName#",
            "publishers_list/list",
            `${t(`createNew`)} ${t("publishersListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`createNew`)} — ${t("publishersListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/publishers_list/:id/edit`}
        exact
        isGranted={(auth) =>
          showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers) && !IS_RETAIL_MODE
        }
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(PublishersListEditPage, [
            "#advertiserName#",
            "publishers_list/list",
            `${t(`edit`)} ${t("publishersListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`edit`)} — ${t("publishersListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/product_feed/list`}
        exact
        isGranted={(auth) => auth.hasAccessToDcoFeed}
        component={LayoutWrapper(AdvertiserLayoutWrapper(ProductFeedListPage))}
        title={t(`Product feed`, { ns: "creatives" })}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/product_feed/create`}
        exact
        isGranted={(auth) => auth.hasAccessToDcoFeed}
        component={LayoutWrapper(AdvertiserLayoutWrapper(ProductFeedCreatePage))}
        title={`${t(`createNew`)} — Product feed`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/product_feed/:id/edit`}
        exact
        isGranted={(auth) => auth.hasAccessToDcoFeed}
        component={LayoutWrapper(AdvertiserLayoutWrapper(ProductFeedEditPage))}
        title={`${t(`edit`)} — ${t(`Product feed`, { ns: "creatives" })}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/apps_list/list`}
        exact
        isGranted={(auth) => showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(AdvertiserLayoutWrapper(AppsListListPage))}
        title={`${t("appsListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/apps_list/create`}
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(AppsListCreatePage, [
            "#advertiserName#",
            "apps_list/list",
            `${t(`createNew`)} ${t("appsListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`createNew`)} — ${t("appsListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/apps_list/:id/edit`}
        exact
        isGranted={(auth) => showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(AppsListEditPage, [
            "#advertiserName#",
            "apps_list/list",
            `${t(`edit`)} ${t("appsListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`edit`)} — ${t("appsListTitle", { list: t("list") })}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/list`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(AdvertiserLayoutWrapper(CampaignGroupListPage))}
        title={`${t("campaignGroup")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/create`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(CampaignGroupCreatePage, [
            "#advertiserName#",
            "campaigns_group/list",
            t("createNewGroup", { ns: "campaignGroups" }),
          ])
        )}
        title={`${t(`createNew_she`)} — ${t("campaignGroup")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/campaigns_group/:id/edit`}
        exact
        isGranted={(auth) => auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(CampaignGroupEditPage, [
            "#advertiserName#",
            "campaigns_group/list",
            t("editGroup", { ns: "campaignGroups" }),
          ])
        )}
        title={`${t(`edit`)} — ${t("campaignGroup")}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/sites_list/list`}
        exact
        isGranted={(auth) =>
          showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers) && !IS_RETAIL_MODE
        }
        component={LayoutWrapper(AdvertiserLayoutWrapper(SitesListListPage))}
        title={`${t("sitesListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/sites_list/create`}
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers && !IS_RETAIL_MODE}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(SitesListCreatePage, [
            "#advertiserName#",
            "sites_list/list",
            `${t(`createNew`)} ${t("sitesListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`createNew`)} — ${t("sitesListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/sites_list/:id/edit`}
        exact
        isGranted={(auth) =>
          showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers) && !IS_RETAIL_MODE
        }
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(SitesListEditPage, [
            "#advertiserName#",
            "sites_list/list",
            `${t(`edit`)} ${t("sitesListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`edit`)} — ${t("sitesListTitle", { list: t("list") })}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/ip_list/list`}
        exact
        isGranted={(auth) =>
          showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers) && !IS_RETAIL_MODE
        }
        component={LayoutWrapper(AdvertiserLayoutWrapper(IPsListListPage))}
        title={`${t("ipListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/ip_list/create`}
        exact
        isGranted={(auth) => showLists(auth) && auth.hasAccessToAdvertisers && !IS_RETAIL_MODE}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(IPsListCreatePage, [
            "#advertiserName#",
            "ip_list/list",
            `${t(`createNew`)} ${t("ipListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`createNew`)} — ${t("ipListTitle", { list: t("list") })}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/ip_list/:id/edit`}
        exact
        isGranted={(auth) =>
          showLists(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers) && !IS_RETAIL_MODE
        }
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(IPsListEditPage, [
            "#advertiserName#",
            "ip_list/list",
            `${t(`edit`)} ${t("ipListTitle", { list: t("list") })}`,
          ])
        )}
        title={`${t(`edit`)} — ${t("ipListTitle", { list: t("list") })}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/creatives/bulk_upload`}
        exact
        isGranted={(auth) => showCreatives(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(BulkUpload, [
            "#advertiserName#",
            "creatives/list",
            `${t("Bulk uploading")} ${t("creative")}`,
          ])
        )}
        title={`${t("Bulk uploading")} — ${t("creative")}`}
      />

      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/creatives/list`}
        exact
        isGranted={(auth) => showCreatives(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(AdvertiserLayoutWrapper(CreativeList))}
        title={`${t("creatives")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/creatives/create`}
        exact
        isGranted={(auth) => showCreatives(auth) && auth.hasAccessToAdvertisers}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(CreativeCreate, [
            "#advertiserName#",
            "creatives/list",
            `${t(`createNew`)} ${t("creative").toLowerCase()}`,
          ])
        )}
        title={`${t(`createNew`)} — ${t("creative")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/creatives/:id/edit`}
        exact
        isGranted={(auth) => showCreatives(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(CreativeEdit, [
            "#advertiserName#",
            "creatives/list",
            `${t(`edit`)} ${t("creative").toLowerCase()}`,
          ])
        )}
        title={`${t(`edit`)} — ${t("creative")}`}
      />
      <PrivateRoute
        path={`${paths.advertisersBase}/:advertiserId/creatives/:id/copy`}
        exact
        isGranted={(auth) => showCreatives(auth) && (auth.hasAccessToAdvertisers || auth.hasAccessToReadAdvertisers)}
        component={LayoutWrapper(
          AdvertiserInnerLayoutWrapper(CreativeCopy, [
            "#advertiserName#",
            "creatives/list",
            `${t(`copy`)} ${t("creative").toLowerCase()}`,
          ])
        )}
        title={`${t(`copy`)} — ${t("creative")}`}
      />

      <RedirectIfAuthRoute path="/auth" exact component={AuthPage} />

      <RedirectIfAuthRoute path="/register" exact component={RegisterPage} />
      <PrivateRoute
        path="/sites/list"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(SitesListPage)}
        title={`${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:siteId/containers/list"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(SiteLayoutWrapper(ContainersListPage))}
        title={`Containers — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:siteId/containers/:containerId/edit"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(withBreadcrumbs(ContainersEditPage, optionsType.containersSite))}
        title={`${t(`edit`)} — ${t("Site containers", { ns: "containers" })} — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:siteId/containers/:containerId/pixels/list"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(withBreadcrumbs(PixelsListPage, optionsType.pixelsContainerSite))}
        title={`${t("sites")} — ${t("Site containers", { ns: "containers" })} — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:siteId/containers/:containerId/pixels/:pixelId/edit"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(withBreadcrumbs(PixelsEditPage, optionsType.pixelContainerSite))}
        title={`${t(`edit`)} — ${t("sites")} — ${t("Site containers", { ns: "containers" })} — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:siteId/containers/:containerId/pixels/create"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(withBreadcrumbs(PixelsCreatePage, optionsType.pixelCreateContainerSite))}
        title={`${t(`createNew`)} — ${t("sites")} — ${t("Site containers", { ns: "containers" })} — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:siteId/containers/create"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(withBreadcrumbs(ContainersCreatePage, optionsType.containerSite))}
        title={`${t(`createNew`)} — ${t("Site containers", { ns: "containers" })} — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/:id/edit"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(SitesEditPage)}
        title={`${t(`edit`)} — ${t("pixels")}`}
      />
      <PrivateRoute
        path="/sites/create"
        exact
        isGranted={(auth) => auth.hasAccessToPixels}
        component={LayoutWrapper(SitesCreatePage)}
        title={`${t(`createNew`)} — ${t("pixels")}`}
      />

      <PrivateRoute
        path="/audience_reports"
        exact
        isGranted={(auth) => auth.hasAccessToAudienceReports}
        component={LayoutWrapper(AudienceReportsPage)}
        title={`${t("audienceReports")}`}
      />

      <PrivateRoute
        path="/ord/:ordTabType"
        exact
        isGranted={(auth) => auth.isLogged}
        component={LayoutWrapper(OrdPage)}
        title={`${t("ord")}`}
      />

      <PrivateRoute
        path="/ord/agency/:agencyId/contract/create"
        exact
        isGranted={(auth) => auth.isLogged}
        component={LayoutWrapper(ContractCreatePage)}
        title={`${t("ord")}`}
      />

      <PrivateRoute
        path="/ord/agency/:agencyId/contract/:contractId/edit"
        exact
        isGranted={(auth) => auth.isLogged}
        component={LayoutWrapper(ContractEditPage)}
        title={`${t("ord")}`}
      />

      <PrivateRoute
        path="/ord/agency/:agencyId/invoice/create"
        exact
        isGranted={(auth) => auth.hasAccessToInvoices}
        component={LayoutWrapper(InvoiceCreatePage)}
        title={`${t("ord")}`}
      />

      <PrivateRoute
        path="/ord/agency/:agencyId/invoice/:id/edit"
        exact
        isGranted={(auth) => auth.hasAccessToInvoices}
        component={LayoutWrapper(InvoiceEditPage)}
        title={`${t("ord")}`}
      />

      <PrivateRoute
        path="/ord/agency/:agencyId/invoice/:id/report"
        exact
        isGranted={(auth) => auth.hasAccessToInvoices && auth.hasAccessToCompoundInvoices}
        component={() => LayoutWrapper(InvoiceReportPage)({ splitByAgencies: false })}
        title={`${t("ord")}`}
      />

      <PrivateRoute
        path="/ord/agency/:agencyId/invoice/:id/report-split"
        exact
        isGranted={(auth) => auth.hasAccessToInvoices && auth.hasAccessToCompoundInvoices}
        component={() => LayoutWrapper(InvoiceReportPage)({ splitByAgencies: true })}
        title={`${t("ord")}`}
      />

      <Route exact path="/403" component={LayoutWrapper(AccessDenied)} />

      <Route component={LayoutWrapper(NotFound)} />
    </Switch>
  );
};

export default Routes;
